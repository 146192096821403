import { useState } from "react";
const DropdownOption = ({title, items, style, icon, onItemClick, onLogout}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [activeOption, setActiveValue] = useState(null);

    const handleActiveDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    const itemDropdownActive = (index) => {
        setActiveValue(index);
        setDropdownOpen(false);
        if (items[index].label === "Logout") {
            if (onLogout) {
                onLogout();
            }
        } else {
            onItemClick(index);
        }
    }

    return (
        <div className="relative">
            <button className={`rounded-full font-semibold text-sm w-fit flex items-center gap-2 whitespace-nowrap ${style}`} onClick={handleActiveDropdown}>
                {title}
                {icon}
            </button>
            <ul className={`dropdown-menu min-w-64 w-full absolute top-full right-0 bg-white shadow-normal10 rounded-lg py-3 mt-2 hidden pointer-events-none transition-all duration-150 ease-linear z-10 ${dropdownOpen ? 'active' : ''}`}>
                {items.map((item, index) => (
                    <li key={index}>
                        <button onClick={() => itemDropdownActive(index)} className={`flex items-center gap-2 w-full px-4 py-2 my-1 active:bg-sekunder-60 font-semibold text-sm text-slate-600 dark:text-slate-300 ${activeOption === index ? 'bg-sekunder-60 hover:bg-sekunder-40 text-white' : 'hover:bg-slate-100'}`}>{item.label}</button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default DropdownOption;