import axios from "axios";
import API_URLS from "./config";

export const getProvinsiList = async () => {
    const response = await axios.get('https://servicecrm.salokapark.app/api/get_provinces');
    return response.data
}

export const getKotaKabupatenList = async (id_province) => {
    const response = await axios.get(`https://servicecrm.salokapark.app/api/get_cities?id_province=${id_province}`);
    return response.data;
}

export const postLogin = async (username, password) => {
    try {
        const response = await axios.post(API_URLS.cms+'/api/partner-login', {
            username,
            password
        });

        return response;
    } catch (error) {
        console.error('Login failed:', error);
        throw error;
    }
};

//getDetail Partner
export const getDetailPartner = async (idPartner) => {
    const response = await axios.get(API_URLS.cms+'/api/partner-detail?partner_id='+idPartner);
    return response;
}

//Cancel Reservasi

