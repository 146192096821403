
const API_URLS = {
    reservasi: 'https://servicereservasi.salokapark.app',
    cms: 'https://servicecms.salokapark.app',
    // reservasi: 'http://192.168.0.139:8001',
    // cms: 'http://192.168.0.139:8000',
    secretKey: '//FatmaW4T!_154',
};
  
  export default API_URLS;
  

  