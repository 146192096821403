import LayoutMain from "../../layouts/layout-main";
import React from "react";
import { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import config from "../../config";
import axios from "axios";
import API_URLS from "../../config";
import { EncryptStorage } from "encrypt-storage";
import { IoSettings } from "react-icons/io5";
import { FaCircleXmark } from "react-icons/fa6";
import Swal from "sweetalert2";


export const encryptStorage = new EncryptStorage("//FatmaW4T!_154", {
  storageType: "sessionStorage",
});

const Profile = () => {
  useEffect(() => {
    document.title = "Profile - Partner Saloka Park";
  });

  const [detailProfil, setDetailProfile] = useState([]);
  //Modal Ganti Password
  const [showModalGantiPassword, setShowModalGantiPassword] = useState(false);
  const [valuePasswordLama, setValuePasswordLama] = useState("");
  const [valuePasswordBaru, setValuePasswordBaru] = useState("");

  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  const getUsernameFromLocalStorage = (secretKey) => {
    const encryptedPartnerID = sessionStorage.getItem("encryptedpartnerID");
    if (encryptedPartnerID) {
      const decryptedPartnerID = decryptData(encryptedPartnerID, secretKey);
      return decryptedPartnerID;
    }
    return null;
  };

  const secretKey = config.secretKey;
  const partnerId = getUsernameFromLocalStorage(secretKey);
  const logoPartner = encryptStorage.getItem("encryptedpartnerLogo");

  const handleModalGantiPassword = () => {
    setShowModalGantiPassword(!showModalGantiPassword);
  }

  const handleValuePasswordLama = (e) => {
    const value = e.target.value;
    setValuePasswordLama(value);
  }

  const handleValuePasswordBaru = (e) => {
    const value = e.target.value;
    setValuePasswordBaru(value);
  }

  const handleGantiPassword = async (e) => {
    try{
      console.log(valuePasswordLama);
      console.log(valuePasswordBaru);
      console.log(partnerId);

      const formData = new FormData();
      formData.append("partner_id", partnerId);
      formData.append("old_password", valuePasswordLama);
      formData.append("new_password", valuePasswordBaru);

      setShowModalGantiPassword(!showModalGantiPassword);

      if(!valuePasswordBaru || !valuePasswordLama){
        Swal.fire({
          title: "Oops!",
          text: "Password lama dan password baru tidak boleh kosong",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }else if(valuePasswordLama === valuePasswordBaru){
        Swal.fire({
          title: "Oops!",
          text: "Password lama dan password baru tidak boleh sama",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }

      Swal.fire({
        title: "Loading!",
        text: "Proses Ubah Password",
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await axios.post(
        `${API_URLS.cms}/api/partner-change-password`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);
      if (response.status === 200) {
        Swal.close();
        Swal.fire({
          title: "Sukses",
          text: "Password Berhasil di Ubah",
          imageUrl: "/img/STK-20230906-WA0025.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      } else {
        Swal.close();
        Swal.fire({
          title: "Oops!",
          text: "Terjadi Kesalahan, Harap Hubungi Admin Kami",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }

    }catch (error) {
      Swal.close();
      Swal.fire({
        title: "Oops!",
        text: "Password lama anda salah, Silahkan Coba Lagi",
        imageUrl: "/img/STK-20230906-WA0006.webp",
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Custom image",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      console.error(error);
    }
  }

  React.useEffect(() => {
    axios
      .get(
        API_URLS.cms + "/api/partner-detail?partner_id=" + partnerId
      )
      .then((response) => {
        let reservations = response.data;
        
        setDetailProfile(reservations);
        console.log(reservations);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  

  return (
    <>
      <LayoutMain>
        <div className="w-full px-5 md:px-10 pt-9">
          <div className="w-full flex flex-col xl:flex-row gap-6 2xl:gap-12">
            <div className="w-full xl:w-2/5 py-5 rounded-lg border border-gray-200">
              <div className="w-full flex items-center gap-4 px-4 pb-4 border-b border-gray-200">
                <img src={logoPartner} alt="" className="w-16 h-auto" />
                <div className="flex flex-col">
                  <strong className="text-lg">
                    {detailProfil.partner_name}
                  </strong>
                  <span className="text-base text-slate-600">
                    {detailProfil.partner_username}
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-col gap-4 px-4 py-5">
                  {/* <div className="w-full flex flex-col gap-2">
                    <span className="text-sm font-semibold text-slate-600">ID Partner</span>
                    <span className="text-sm">{detailProfil.partner_ids}</span>
                  </div> */}
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-sm font-semibold text-slate-600">Alamat Email</span>
                    <span className="text-sm">{detailProfil.partner_email}</span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-sm font-semibold text-slate-600">Nomor Telphone</span>
                    <span className="text-sm">{detailProfil.partner_phone}</span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-sm font-semibold text-slate-600">Alamat</span>
                    <span className="text-sm">{detailProfil.partner_address}</span>
                  </div>
              </div>
            </div>
            <div className="w-full xl:w-3/5">
              <div className="mb-4 border-b-2 border-gray-200 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-tab" data-tabs-toggle="#default-tab-content" role="tablist">
                  <li className="me-2" role="presentation">
                      <button className="flex items-center gap-2 p-4 border-b-2 border-sekunder-60 rounded-t-lg text-sekunder-60 font-semibold" id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                        <IoSettings className="text-xl"/>
                        Pengaturan
                      </button>
                  </li>
                </ul>
              </div>
              <div id="default-tab-content">
                  <div className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <div className="w-full flex flex-col gap-4 bg-white rounded-lg">
                        <div className="w-full px-4 py-4 flex items-center border-b border-gray-200">
                          <strong className="text-base">Keamanan & Autentikasi</strong>
                        </div>
                        <div className="w-full pb-5 px-4">
                          <div className="w-full flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                            <div className="flex flex-col gap-1">
                              <strong className="text-sm">Ganti Password</strong>
                              <span className="text-sm">Pastikan kata sandi anda aman</span>
                            </div>
                            <button
                              onClick={handleModalGantiPassword}
                              className="w-fit h-fit px-4 py-3 lg:rounded-full md:rounded-full  rounded-full flex items-center justify-center gap-2 bg-primer-60 font-semibold text-white text-sm hover:bg-primer-80 transition-colors duration-200 ease-in-out whitespace-nowrap"
                            >
                              Ubah Password
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="px-10 lg:pl-10 lg:pr-16 mt-12 py-4 border-t-2 border-t-slate-100 dark:border-t-slate-700">
            <p className="text-sm text-slate-400">
              Copyright © 2023 Aplikasi By IT SALOKA
            </p>
          </div>
        </div>
      </LayoutMain>
      <section className={`modal-container ${showModalGantiPassword === true ? "flex" : "hidden"} items-center justify-center max-w-full w-full max-h-screen h-full fixed top-0 left-0 z-[9999] px-5`}>
        <div className={`show-modal w-full max-w-full md:max-w-lg rounded-lg bg-white dark:bg-slate-700 py-4 ${showModalGantiPassword === true ? "active" : ""}`}>
          <div className="w-full flex items-center justify-between relative pb-4 border-b border-gray-2 px-4">
            <strong>Ubah Password</strong>
            <button
              onClick={() => setShowModalGantiPassword(false)}
            >
              <FaCircleXmark className="text-2xl" />
            </button>
          </div>
          <div className="w-full flex flex-col items-center justify-center gap-2 pt-6 px-4">
            <form action="" className="w-full">
              <div className="w-full flex flex-col gap-6">
                <div className="w-full flex flex-col gap-3 px-2">
                  <label htmlFor="password-lama" className="text-sm font-medium">Password Lama</label>
                  <input 
                    type="password"
                    name="password-lama"
                    id="password-lama"
                    onChange={(e) => handleValuePasswordLama(e)}
                    className="w-full px-4 py-3 border border-slate-300 dark:border-slate-600 rounded-lg focus:outline-none focus:border-informasi-60 text-slate-700 dark:text-slate-50 bg-white dark:bg-slate-800 text-sm focus:ring-2 focus:ring-informasi-40"
                    placeholder="Masukan Password Lama"
                  />
                </div>
                <div className="w-full flex flex-col gap-3 px-2">
                  <label htmlFor="password-baru" className="text-sm font-medium">Password Baru</label>
                  <input 
                    type="password"
                    name="password-baru"
                    id="password-baru"
                    onChange={(e) => handleValuePasswordBaru(e)}
                    className="w-full px-4 py-3 border border-slate-300 dark:border-slate-600 rounded-lg focus:outline-none focus:border-informasi-60 text-slate-700 dark:text-slate-50 bg-white dark:bg-slate-800 text-sm focus:ring-2 focus:ring-informasi-40"
                    placeholder="Masukan Password Baru"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="w-full flex item-center justify-end gap-3 mt-6 px-4">
            <button
              onClick={() => setShowModalGantiPassword(false)}
              className="close-modal px-4 py-3 rounded-lg bg-slate-300 text-slate-950 text-sm hover:bg-slate-200 w-fit"
              
            >
              Batal
            </button>
            <button
            onClick={handleGantiPassword}
              className="close-modal px-4 py-3 rounded-lg text-slate-50 text-sm bg-primer-60 hover:bg-primer-80 transition-colors duration-200 ease-in-out w-fit"
            >
              Ganti Password
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
