import React from "react";

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("//FatmaW4T!_154", {
  storageType: "sessionStorage",
});
const TiketCard = (props) => {

  const {title, desc, price, children , price_adjust, qty, ticket_order ,index , onPriceUpChange} = props;

  const [adjust_price, setAdjustPrice] = React.useState(0);


  const handleChange = (e, index) => {
    const inputValue = e.target.value.replace(/\./g, "");
    let newArr = [...ticket_order];
    newArr[index].price_up = inputValue;

    if (inputValue <= 0) {
      props.onPriceUpChange(false);
      setAdjustPrice(0);
    } else {
      props.onPriceUpChange(true);
      setAdjustPrice(inputValue);
    }

    encryptStorage.setItem("ticketOrder", JSON.stringify(newArr));
  };


  

  // React.useEffect(() => {
  //   if (props.qty < 20)
  //   setAdjustPrice(price_adjust);
  //   else if(props.qty >= 20 && props.qty < 100)
  //   setAdjustPrice(props.unformat_price * 0.8);
  //   else if(props.qty >= 100 && props.qty < 1000)
  //   setAdjustPrice(props.unformat_price * 0.75);
  //   else if (props.qty >= 1000){
  //   setAdjustPrice(props.unformat_price * 0.7);
  //   }
  //   let newArr = [...ticket_order];
  //   newArr[index].price_up = price_adjust;
  //   encryptStorage.setItem("ticketOrder", JSON.stringify(newArr));
  // }, [price_adjust,qty]);

  const formatRupiah = (value) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'decimal',
      currency: 'IDR',
      minimumFractionDigits: 0,
  }).format(value);
  };
 
  return (
    <>
      <div className="w-full py-4 px-6 flex flex-col xl:flex-row items-center justify-between gap-6 rounded-xl border-2 border-slate-100">
        <div className="w-full flex items-center gap-4">
          <div className="w-16 h-16">
            <img src="/img/tiket.svg" alt="" className="w-full h-full" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <strong>{title}</strong>
            <span className="text-sm text-slate-600">{desc}</span>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export default TiketCard;
