import React from 'react';
// import { NavLink } from "react-router-dom";
import DropdownOption from "../../components/dropdown/dropdown";
import LayoutMain from "../../layouts/layout-main";
import { HiMiniMagnifyingGlass } from "react-icons/hi2";
import { IoSettings } from "react-icons/io5";
import { FaCircle } from "react-icons/fa";
import API_URLS from '../../config';
import CryptoJS from 'crypto-js';
import config from '../../config';

import axios from 'axios';




const Customer = () => {

    const dropdownItems = [
        { label: 'Created' },
        { label: 'Success' },
    ];

    const [customer, setCustomer] = React.useState([]);
    const decryptData = (encryptedData, secretKey) => {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    };

    const getUsernameFromLocalStorage = (secretKey) => {
        const encryptedPartnerID = sessionStorage.getItem('encryptedpartnerID');
        if (encryptedPartnerID) {
            const decryptedPartnerID = decryptData(encryptedPartnerID, secretKey);
            return decryptedPartnerID;
        }
        return null;
    };

    const secretKey = config.secretKey;
    const partnerId = getUsernameFromLocalStorage(secretKey);

  

    React.useEffect(() => {
        axios.get(API_URLS.reservasi+'/api/partner-customer?partner_id=' + partnerId)
        .then((response) => {
            let customers = response.data.partner_customer;
            console.log(customers);
            let newCustomer = [];
            for (let index = 0; index < customers.length; index++) {
                newCustomer.push({
                    customer_id: customers[index].id,
                    customer_name: customers[index].partner_customer_name,
                    customer_phone: customers[index].partner_customer_phone,
                    customer_email: customers[index].partner_customer_email,
                    customer_province: customers[index].partner_customer_province,
                    customer_city: customers[index].partner_customer_city,
                })
            }
            setCustomer(newCustomer);

        }).catch((error) => {
            console.log(error);
        })
    }, [partnerId]);
    

    return (
        <>
            <LayoutMain>
                <div className="w-full px-5 md:px-10 pt-24">
                    <strong className="inline-block mt-2 text-xl text-sekunder-60">Customer</strong>
                    <div className="max-w-full w-full py-6 rounded-lg">
                        <span className="inline-block font-semibold whitespace-nowrap">List Customer</span>
                        <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4 py-2 mt-4">
                            <div className="w-full flex items-center gap-2">
                                <div className="w-full lg:w-1/2 flex items-center">
                                    <div className="w-full relative">
                                        <input type="text" id="serach-tabel" placeholder="Cari Data" className="w-full px-10 py-3 border border-slate-300 bg-white dark:bg-slate-600 rounded-full focus:outline-none focus:border-primer-60 text-slate-800 dark:text-slate-50 text-sm focus:ring-2 focus:ring-primer-40"/>
                                        <HiMiniMagnifyingGlass className="absolute top-3 left-3.5 text-xl" />
                                        <div className="absolute top-1 right-1">
                                            <DropdownOption style={`p-2.5 bg-slate-200 hover:bg-primer-60 text-white group`} title="" items={dropdownItems} icon={<IoSettings className="text-base font-semibold text-primer-60 group-hover:text-white" />} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <NavLink to={"/reservasi/create-reservasi"} className="w-fitt px-4 py-3 rounded-full flex items-center justify-center gap-2 bg-primer-60 font-semibold text-white text-sm hover:bg-primer-80 transition-colors duration-200 ease-in-out whitespace-nowrap">
                                <HiPlus className="text-base"/>
                                Add Customer
                            </NavLink> */}
                        </div>
                        <div className="w-full py-4">
                            <div className="overflow-auto">
                            <table className="w-full">
                                <thead className="bg-slate-100 rounded-lg">
                                    <tr>
                                    <th className="pl-6 pr-3 py-3 text-sm font-semibold tracking-wide text-left">No</th>
                                        <th className="pl-6 pr-3 py-3 text-sm font-semibold tracking-wide text-left">Customer Name</th>
                                        <th className="p-3 text-sm font-semibold tracking-wide text-left">WhatsApp</th>
                                        <th className="p-3 text-sm font-semibold tracking-wide text-left">Email</th>
                                        <th className="p-3 text-sm font-semibold tracking-wide text-left">Privinsi</th>
                                        <th className="p-3 text-sm font-semibold tracking-wide text-left">Kota</th>
                                        <th className="pr-6 pl-3 py-3 text-sm font-semibold tracking-wide text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y-2 divide-slate-100 dark:divide-slate-600">
                                {customer.map((item,index) => (
                                           <tr className="bg-white">
                                            <td className="pl-6 pr-3 py-3 text-sm tracking-wide text-left whitespace-nowrap">{index+1}</td>
                                           <td className="pl-6 pr-3 py-3 text-sm tracking-wide text-left whitespace-nowrap">{item.customer_name}</td>
                                           <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">{item.customer_phone}</td>
                                           <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">{item.customer_email}</td>
                                           <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">{item.customer_province}</td>
                                           <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">{item.customer_city}</td>
                                           <td className="pr-6 pl-3 py-3 text-sm tracking-wide text-left">
                                               <div className="w-fit flex item-center gap-2 px-3 py-2 rounded-full bg-blue-50">
                                                   <FaCircle className="text-blue-600 text-sm"/>
                                                   <span className="font-semibold whitespace-nowrap text-blue-600 text-xs uppercase">on proccess</span>
                                               </div>
                                           </td>
                                       </tr>
                                ))}
                                 
                                   
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutMain>
        </>
    );
}

export default Customer;