import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { postLogin } from "../../service";
import CryptoJS from "crypto-js";
import config from "../../config";
import { NavLink } from "react-router-dom";
import { BsFillEyeFill, BsEyeSlashFill } from "react-icons/bs";
import Swal from "sweetalert2";

import { EncryptStorage } from "encrypt-storage";

export const encryptStorage = new EncryptStorage("//FatmaW4T!_154", {
  storageType: "sessionStorage",
});

gsap.registerPlugin(CSSPlugin);
const AuthLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const image = imageRef.current;

    gsap.to(image, {
      duration: 0.3,
      scale: 0.75,
      x: -3000,
      opacity: 0,
      ease: "easeInOut",
      onComplete: () => {
        gsap.to(image, {
          duration: 3,
          scale: 1,
          x: 0,
          opacity: 1,
          ease: "easeInOut",
        });
      },
    });
  }, []);

  const handleLogin = async () => {
    Swal.fire({
      title: "Loading!",
      text: "Please wait...",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const userData = await postLogin(username, password);
      if (userData.status === 200) {
        Swal.close();
        Swal.fire({
          title: "Sukses",
          text: "Berhasil login!",
          imageUrl: "img/STK-20230906-WA0025.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: false,
          timer: 2500,
        });

        const partnerID = userData.data.partner.partner_ids;
        const partnerName = userData.data.partner.partner_name;
        const partnerLogo = userData.data.partner.partner_logo;
        const statusPartner = userData.data.partner.is_banned;
        const deposit = userData.data.partner.partner_deposit;
        const secretKey = config.secretKey;

        const encryptData = (partnerID, secretKey) => {
          const encryptedData = CryptoJS.AES.encrypt(
            partnerID,
            secretKey
          ).toString();
          return encryptedData;
        };

        const partnerIDLocalStorage = (partnerID, secretKey) => {
          const encryptedpartnerID = encryptData(partnerID, secretKey);
          sessionStorage.setItem("encryptedpartnerID", encryptedpartnerID);
        };

        const encryptDataPartnerName = (partnerName, secretKey) => {
          const encryptDataPartnerName = CryptoJS.AES.encrypt(
            partnerName,
            secretKey
          ).toString();
          return encryptDataPartnerName;
        };
        const partnerNameLocalStorage = (partnerName, secretKey) => {
          const encryptedpartnerName = encryptDataPartnerName(
            partnerName,
            secretKey
          );
          sessionStorage.setItem("encryptedpartnerName", encryptedpartnerName);
        };
        partnerNameLocalStorage(partnerName, secretKey);
        partnerIDLocalStorage(partnerID, secretKey);
        encryptStorage.setItem("encryptedpartnerLogo", partnerLogo);
        encryptStorage.setItem("statusPartner", partnerName);
        encryptStorage.setItem("deposit", deposit);
        window.location.href = "/dashboard";
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "System error",
          text: "Silahkan coba lagi atau hubungi Developer",
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      Swal.close();
      Swal.fire({
        title: "Oops",
        text: "Pastikan Username dan Password anda benar!",
        imageUrl: "img/STK-20230906-WA0006.webp",
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Custom image",
        showConfirmButton: true,
      });
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const isAuthenticated =
      sessionStorage.getItem("encryptedpartnerID") !== null;
    if (isAuthenticated) {
      window.location.href = "/dashboard";
    }
  });

  return (
    <section className="font-Roboto max-w-full w-full min-h-screen h-full relative bg-white">
      <div className="w-full min-h-screen h-full grid grid-cols-1 xl:grid-cols-1">
        <div className="w-full h-full flex flex-col items-center justify-center px-6 lg:px-16 z-10">
          <div className="w-full xl:w-3/4 2xl:w-1/2 pt-6">
            <img src="/img/logo-saloka.png" alt="" className="w-36 h-auto" />
          </div>
          <div className="w-full xl:w-3/4 2xl:w-1/2 pt-6 xl:pt-6">
            <h1 className="inline-block text-xl xl:text-3xl font-bold text-primer-60 my-2">
              Login Saloka Partner
            </h1>
            <p className="text-slate-600">
              Sugeng Rawuh👋, Masukan Username dan Password.
            </p>
          </div>
          <div className="w-full xl:w-3/4 2xl:w-1/2 pt-9">
            <form action="">
              <div className="w-full flex flex-col xl:flex-row gap-4 xl:gap-9">
                <div className="w-full flex flex-col">
                  <label htmlFor="username" className="text-sm font-bold">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="w-full px-0 py-2 border border-b-2 border-l-0 border-t-0 border-r-0 focus:border-sekunder-60 border-slate-300 bg-white focus:ring-0 active:ring-0"
                    placeholder="Masukkan username"
                  />
                </div>
                <div className="w-full flex flex-col relative">
                  <label htmlFor="password" className="text-sm font-bold">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-0 py-2 border border-b-2 border-l-0 border-t-0 border-r-0 focus:border-sekunder-60 border-slate-300 bg-white focus:ring-0 active:ring-0"
                    placeholder="Masukkan password"
                  />
                  <div className="absolute right-1 top-1/2 cursor-pointer">
                    {showPassword ? (
                      <BsFillEyeFill
                        onClick={handleShowPassword}
                        className="text-xl"
                      />
                    ) : (
                      <BsEyeSlashFill
                        onClick={handleShowPassword}
                        className="text-xl"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full mt-4">
                <NavLink className="text-sm font-bold text-primer-60 hover:text-primer-80">
                  Forgot Password?
                </NavLink>
              </div>
              <div className="w-full mt-6">
                <button
                  type="button"
                  className="w-full xl:w-fit px-12 py-3 bg-sekunder-60 hover:bg-sekunder-40 font-semibold text-white rounded-full"
                  onClick={handleLogin}
                >
                  LOGIN
                </button>
              </div>
              <div className="w-full mt-6">
                <p className="text-sm">
                  *I agree with <strong>Term And Condition</strong> and{" "}
                  <strong>Privacy Policy</strong> of Saloka Theme Park
                </p>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full">
          <img
            ref={imageRef}
            src="/img/cakrawala.svg"
            alt="Zoom In Image"
            className="w-56 md:w-96 2xl:w-[520px] h-auto absolute bottom-0 md:bottom-20 2xl:bottom-20 right-0"
          />
        </div>
        <div className="w-full bg-[url('asset/img/bg-login-desktop.svg')] bg-cover bg-center z-10"></div>
      </div>
    </section>
  );
};

export default AuthLogin;
