import axios from "axios";
import { useState, useEffect } from "react";

const PetKunjungan = () => {
  const [operationalHourweekday, setOperationalHourWeekday] = useState("");
  const [operationalHourweekend, setOperationalHourWeekend] = useState("");
  const [operationalHourfriday, setOperationalHourFriday] = useState("");

  useEffect(() => {
    const getDetailJamOprasional = async () => {
      try {
        await axios
          .get(`https://servicecms.salokapark.app/api/getopshour`)
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              setOperationalHourWeekday(response.data.weekday);
              setOperationalHourWeekend(response.data.weekend);
              setOperationalHourFriday(response.data.friday);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    };

    getDetailJamOprasional();
  }, []);

  return (
    <>
      <strong>Petunjuk Kunjungan</strong>
      <div className="w-full  xl:w-3/5 flex flex-col gap-1 mt-6">
        <span>Jam Operasional</span>
        <p className="text-justify">
          Jam operasional Saloka untuk hari Senin-Kamis adalah{" "}
          {operationalHourweekday}, hari Jumat adalah {operationalHourfriday},
          hari Sabtu dan Minggu adalah {operationalHourweekend}. Pada hari libur
          nasional, jam operasional dapat berubah sewaktu-waktu.
        </p>
      </div>
      <div className="w-full  xl:w-3/5 flex flex-col gap-1 mt-4">
        <span>Harga Tiket</span>
        <p className="text-justify">
          Harga tiket pada hari Senin-Jumat adalah Rp 120.000 dan hari
          Sabtu-Minggu adalah Rp 150.000 yang berlaku sebagai tiket terusan.
          Anak-anak dengan tinggi badan di bawah 90 cm belum berlaku harga
          tiket. Lansia di atas 55 tahun berlaku harga khusus dengan menunjukkan
          KTP di loket Saloka. Pembelian tiket bisa dilakukan di offline/loket
          tiket dan online pada website (maksimal H-1 kedatangan) dan merchant
          yang bekerjasama dengan Saloka. Tidak berlaku refund untuk pembatalan
          tiket yang sudah dibeli.
        </p>
      </div>
      <div className="w-full  xl:w-3/5 flex flex-col gap-1 mt-4">
        <span>Pertunjukan di Saloka</span>
        <p className="text-justify">
          Pertunjukan spektakuler Baru Klinthing Show hadir di hari Jumat,
          Sabtu, Minggu, dan di hari libur nasional pada jam 18.15 WIB dan
          Dancing Fountain setiap hari di jam 15.00 WIB. Lihat lebih lengkap
          mengenai pertunjukan di Saloka pada bagian Show & Event.
        </p>
      </div>
    </>
  );
};

export default PetKunjungan;
