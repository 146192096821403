import React, { useState, useEffect } from "react";

const FloatingLabelInput = ({ id, label, type, value, onChange, onPaste, children }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(Boolean(value));

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleInputChange = (e) => {
        onChange(e);
        setHasValue(Boolean(e.target.value));
    };

    useEffect(() => {
        setHasValue(Boolean(value));
    }, [value]);

    useEffect(() => {
        if (value) {
            setIsFocused(true);
        } else {
            setIsFocused(false);
        }
    }, [value]);

    return (
        <div className={`relative w-full my-1`}>
            <label
                className={`absolute transition-all ${
                    (isFocused || hasValue)
                        ? 'text-sm left-4 -top-4 p-1 text-primer-60 ' + (!hasValue ? 'text-primer-60' : 'text-slate-600') + ' bg-white'
                        : 'left-11 text-sm top-4 text-slate-600'
                }`}
                htmlFor={id}
            >
                {label}
            </label>
            {children}
            <input
                id={id}
                type={type}
                className={`w-full pl-11 pr-4 py-3 rounded-lg border-b border-gray-300 outline-none focus:ring-1 focus:ring-primer-60 focus:border-primer-60 transition-all`}
                value={value}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onPaste={onPaste}  
            />
        </div>
    );
};

export default FloatingLabelInput;
