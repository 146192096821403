import React, { useState, useEffect } from 'react';

const SelectInput = ({ options, label, placeholder, onSelectChange}) => {
    const [active, setActive] = useState(false);
    const [selectedValue, setSelectedValue] = useState('');
    const [selectIndex, setSelectIndex] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    const handleOptionClick = (value, id) => {
        setSelectedValue(value);
        setSelectIndex(id);
        setActive(false); 
        onSelectChange && onSelectChange(id);
    };

    const handleInputChange = (e) => {
        setSearchValue(e.target.value.toUpperCase());
        setSelectIndex(e.target.dataset.index);
    };

    const filteredOptions = options.filter((option) =>
        option.label?.toUpperCase().includes(searchValue)
    );


    return (
        <div className="w-full flex flex-col gap-4 my-1">
            {/* <strong className="text-xs text-slate-600 dark:text-slate-300">{label}</strong> */}
            <div className={`w-full relative select-box ${active ? 'active' : ''}`}>
                <div
                    className="show-menu w-full flex items-center justify-between border border-slate-300 dark:border-slate-500 px-4 py-3 rounded-lg cursor-pointer bg-white dark:bg-slate-800"
                    onClick={() => setActive(!active)}
                >
                    <input
                        className="w-full border-none focus:ring-0 active:ring-0 p-0 focus:border-none active:border-none text-box focus:outline-none active:outline-none text-base placeholder:text-slate-950 dark:placeholder:text-slate-50 bg-transparent font-medium"
                        type="text"
                        value={selectedValue}
                        data-index={selectIndex}
                        placeholder={label}
                        readOnly
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="svg w-5 h-5 font-semibold transition-transform duration-150 ease-in-out"
                    >
                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clipRule="evenodd" />
                    </svg>
                </div>
                <ul className="menu-list w-full max-h-56 overflow-x-auto mt-4 py-3 hidden pointer-events-none bg-white dark:bg-slate-700 shadow-md rounded-lg transition-all duration-200 ease-linear absolute top-full right-0 z-50">
                    <li className="px-4">
                        <input
                        className="w-full text-sm px-4 py-3 rounded-lg border-b border-slate-300 outline-none focus:ring-1 focus:ring-primer-60 focus:border-primer-60 my-1"
                        type="text"
                        value={searchValue}
                        onChange={handleInputChange}
                        placeholder={placeholder}
                        data-index={selectIndex}
                        />
                    </li>
                    {filteredOptions.map((option, index) => (
                        <li key={index}>
                            <a
                                href="#"
                                className={`block w-full px-5 py-3 mt-2 dark:hover:bg-slate-500 text-sm  cursor-pointer ${selectedValue === option.label ? 'bg-primer-60 font-semibold hover:bg-primer-40 text-white' : 'hover:bg-slate-100'}`}
                                onClick={() => handleOptionClick(option.label, option.id)}
                                data-id={option.id}
                            >
                                {option.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SelectInput;
