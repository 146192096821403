import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './index.css';
import Dashboard from './pages/dashboard/dashboard';
import TransaksiTiket from './pages/transaksi-tiket/transaksi-tiket';
import NotaTransaksiTiket from './pages/transaksi-tiket/nota-transaksi';
import Reservasi from './pages/reservasi/reservasi';
import Invoice from './pages/invoice/invoice';
import CreateReservetion from './pages/transaksi-tiket/create-reservation';
import AuthLogin from './pages/Auth/login';
import Customer from './pages/customer/customer';
import Insentif from './pages/reservasi/insentif';
import Profile from './pages/profile/profile';
import TagihanPDF from './pages/invoice/invoice-pdf';

const ProtectedRoute = ({ element }) => {
    const isAuthenticated = sessionStorage.getItem('encryptedpartnerID') !== null;
    const navigate = useNavigate();

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    } 
    return element;
};

const ProtectedRouteLogin = ({ element }) => {
    const isAuthenticated = sessionStorage.getItem('encryptedpartnerID') !== null;
    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return element;
}

const root = createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route
                path="/"
                element={
                    <ProtectedRouteLogin element={<AuthLogin />} />
                }
            />
            <Route
                path="/dashboard"
                element={
                    <ProtectedRoute element={<Dashboard />} />
                }
            />
            <Route
                path="/dashboard/profile"
                element={
                    <ProtectedRoute element={<Profile />} />
                }
            />
            <Route 
                path='/tiket/data-transaksi' 
                element={
                    <ProtectedRoute element={<TransaksiTiket />} />
                } 
            />
            <Route 
                path='/tiket/data-pemesan' 
                element={
                    <ProtectedRoute element={<NotaTransaksiTiket />} />
                } 
            />
            <Route 
                path='/dashboard/reservasi' 
                element={
                    <ProtectedRoute element={<Reservasi />} />
                } 
            />

            <Route 
                path='/dashboard/invoice' 
                element={
                    <ProtectedRoute element={<Invoice />} />
                } 
            />
            <Route 
                path='/reservasi/create-reservasi' 
                element={
                    <ProtectedRoute element={<CreateReservetion />} />
                } 
            />
            <Route 
                path='/partner/insentif' 
                element={
                    <ProtectedRoute element={<Insentif />} />
                } 
            />
            <Route 
                path='/customer' 
                element={
                    <ProtectedRoute element={<Customer />} />
                } 
            />
            <Route
                path='/dashboard/invoice/tagihan-pdf'
                element={
                    <ProtectedRoute element={<TagihanPDF/>}/>
                }
            />
        </Routes>
    </BrowserRouter>
);
