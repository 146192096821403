import React from "react";
import html2pdf from "html2pdf.js";

const TagihanPDF = () => {
  const generatePDF = async () => {
    const input = document.getElementById("download-pdf");

    try {
      const options = {
        margin: 10,
        filename: "generated-pdf.pdf",
        image: { type: "png", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      const pdfBlob = await html2pdf()
        .from(input)
        .set(options)
        .outputPdf("blob");
      const pdfDataUri = URL.createObjectURL(pdfBlob);

      window.open(pdfDataUri, "_blank");
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <>
      {/* <!-- Download PDF  Start--> */}
      <section
        id="download-pdf"
        className={`block font-Roboto max-w-full w-full min-h-screen h-full`}
      >
        <div className="w-full">
          <img src="/img/cop-surat.png" alt="Logo Saloka" className="w-full" />
        </div>
        <div className="w-full flex flex-col items-center justify-center gap-2">
          <strong className="inline-block text-2xl uppercase pb-2 border-b border-gray-950 mt-6">
            INVOICE
          </strong>
          <strong className="text-base">INV-FIN-004-06122023</strong>
        </div>
        <div className="w-full flex gap-2 mt-9">
          <strong className="text-xs">Kepada : </strong>
          <div className="flex flex-col gap-2">
            <strong className="text-xs uppercase">BANK AAA</strong>
            <strong className="text-xs uppercase">
              MENARA BCA GRAND INDONESIA
            </strong>
            <strong className="text-xs uppercase">
              JL. MH. THAMRIN NO. 1, JAKARTA PUSAT
            </strong>
          </div>
        </div>
        <div className="w-full mt-9">
          <table className="w-full">
            <thead>
              <tr>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left">No.</th>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left">
                  Keterangan
                </th>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left">
                  Total of Trx
                </th>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left">
                  UNIT PRICE
                </th>
                <th className="px-3 pb-4 text-xs bg-gray-100 text-left">
                  TOTAL{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-3 pb-3 text-xs border-y border-gray-800 text-left">
                  1
                </td>
                <td className="px-3 pb-3 text-xs border-y border-gray-800 text-left">
                  TAG20241P1/January 2024
                </td>
                <td className="px-3 pb-3 text-xs border-y border-gray-800 text-left">
                  10
                </td>
                <td className="px-3 pb-3 text-xs border-y border-gray-800 text-left whitespace-nowrap">
                  Rp 150.000
                </td>
                <td className="px-3 pb-3 text-xs border-y border-gray-800 text-left whitespace-nowrap">
                  Rp 15.000.000
                </td>
              </tr>
              <tr>
                <td className="px-3 pb-3 text-xs border border-t-0 border-r-0 border-gray-800 text-left font-bold">
                  Terbilang:{" "}
                </td>
                <td className="px-3 pb-3 text-xs border-b border-gray-800">
                  <span className="font-bold italic whitespace-nowrap">
                    Lima Belas Juta Rupiah
                  </span>
                </td>
                <td className="px-3 pb-3 text-xs border-b border-gray-800"></td>
                <td className="px-3 pb-3 text-xs border-b border-gray-800"></td>
                <td className="px-3 pb-3 text-xs border border-y-0 border-gray-800 text-right"></td>
              </tr>
              <tr>
                <td className="px-3 pb-3 text-xs border border-t-0 border-r-0 border-gray-800 text-left font-bold"></td>
                <td className="px-3 pb-3 text-xs border-b border-gray-800"></td>
                <td className="px-3 pb-3 text-xs border-b border-gray-800 text-left uppercase font-bold whitespace-nowrap">
                  GRAND TOTAL
                </td>
                <td className="px-3 pb-3 text-xs border-b border-gray-800"></td>
                <td className="px-3 pb-3 text-xs border border-t-0 border-gray-800 text-right font-bold whitespace-nowrap">
                  Rp 15.000.000, 00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-full pt-9">
          <div className="w-full flex flex-col gap-1">
            <strong className="text-sm">Please make your payment to :</strong>
            <div className="flex items-center gap-2">
              <span className="text-xs">Acc Number </span>
              <span className="text-xs">: 008101222333306</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs">Bank Name </span>
              <span className="text-xs">: BANK RAKYAT INDONESIA (BRI)</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs">Acc Name </span>
              <span className="text-xs">: Panorama Indah Permai PT</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-xs">Branch </span>
              <span className="text-xs">: KCU Salatiga</span>
            </div>
          </div>
        </div>
        <div className="w-full pt-12">
          <div className="w-full flex flex-col items-end gap-10">
            <span className="text-xs">Kab.Semarang , 6 Desember 2023</span>
            <div className="w-full flex flex-col items-end gap-1 pt-10">
              <strong className="inline-block text-xs uppercase pb-2 border-b border-gray-950">
                SON LOKA PRESTI
              </strong>
              <strong className="text-xs">Finance Manager</strong>
            </div>
          </div>
        </div>
        <div className="w-full pt-12"></div>
      </section>
      {/* <!-- Download PDF End--> */}
    </>
  );
};

export default TagihanPDF;
