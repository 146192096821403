import { useState, useEffect } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { Collapse } from "react-collapse";
import axios from "axios";

const FaqDashboard = () => {
  const [dataFaq, setDataFaq] = useState([]);

  const toggleAccordion = (index) => {
    setDataFaq((prevData) =>
      prevData.map((faq, i) =>
        i === index ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  useEffect(() => {
    const getDataFaq = async () => {
      try {
        await axios
          .get(`https://servicereservasi.salokapark.app/api/get-content-faqs`)
          .then((response) => {
            console.log(response);
            if (response.status == 200 && response.data.faqs.length > 0) {
              setDataFaq(response.data.faqs);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    };

    getDataFaq();
  }, []);

  return (
    <>
      {dataFaq.map((item, index) => (
        <div
          className="w-full xl:w-3/5 p-4 border-2 border-natural-40 dark:border-slate-400 rounded-lg mb-4"
          key={index}
        >
          <div
            onClick={() => toggleAccordion(index)}
            className="w-full flex items-center justify-between cursor-pointer gap-4"
          >
            <span className="text-slate-700 dark:text-slate-100">
              {item.tanya}
            </span>
            {item.isOpen ? (
              <AiOutlineMinus className="text-slate-700 dark:text-slate-100 w-5 h-5 cursor-pointer" />
            ) : (
              <AiOutlinePlus className="text-slate-700 dark:text-slate-100 w-5 h-5 cursor-pointer" />
            )}
          </div>
          <Collapse isOpened={item.isOpen}>
            <div className="w-full">
              <p className="mt-3 text-slate-700 dark:text-slate-100">
                {item.jawab}
              </p>
            </div>
          </Collapse>
        </div>
      ))}
    </>
  );
};

export default FaqDashboard;
