import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Card } from "react-rainbow-components";
import LayoutMain from "../../layouts/layout-main";
import { HiMiniMagnifyingGlass, HiPlus } from "react-icons/hi2";
import { FaFilter } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import API_URLS from "../../config";
import axios from "axios";
import { format, parseISO, set } from "date-fns";
import { id } from "date-fns/locale";
import { FaCalendarCheck, FaCalendarDay } from "react-icons/fa";
import CryptoJS from "crypto-js";
import config from "../../config";
import { HiCurrencyDollar } from "react-icons/hi2";
import { formatRupiah } from "../utilities/FormatRupiah";
import { FaChartPie, FaUserLarge, FaUserGroup } from "react-icons/fa6";
import moment from "moment";
// import { Datepicker } from 'flowbite-react';
import Datepicker from "flowbite-datepicker/Datepicker";
import {
  FaCircleXmark,
  FaRegEye,
  FaAngleDown,
  FaCircleCheck,
} from "react-icons/fa6";
import Swal from "sweetalert2";
import { EncryptStorage } from "encrypt-storage";

export const encryptStorage = new EncryptStorage("//FatmaW4T!_154", {
  storageType: "sessionStorage",
});

const Reservasi = () => {
  useEffect(() => {
    document.title = "Reservasi - Partnership Saloka Park";
  }, []);

  const statusPartner = encryptStorage.getItem("statusPartner");
  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  const getUsernameFromLocalStorage = (secretKey) => {
    const encryptedPartnerID = sessionStorage.getItem("encryptedpartnerID");
    if (encryptedPartnerID) {
      const decryptedPartnerID = decryptData(encryptedPartnerID, secretKey);
      return decryptedPartnerID;
    }
    return null;
  };

  const secretKey = config.secretKey;
  const partnerId = getUsernameFromLocalStorage(secretKey);

  let parsedDate;
  let formattedDate;
  let orderDate;
  let formattedorderDate;

  const [dateToday, setDateToday] = useState(moment().format("YYYY-MM-DD"));
  const [reservation, setReservation] = React.useState([]);
  const [currentPage, setCurentPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [pageNumber, setpageNumber] = React.useState(1);
  const [customerName, setCustomerName] = React.useState();
  const [fromItems, setFromItems] = React.useState();
  const [toItems, setToItems] = React.useState();

  const [showFilterOptions, setShowFilterOptions] = React.useState(false);
  const [activeFilter, setActiveFilter] = React.useState();

  //Cancel Reservasi
  const [partnerResvId, setPartnerResvId] = useState();
  const [tanggalKedatangan, setTanggalKedatangan] = useState("");

  //Filter Search
  const [dropdownFilter, setDropdownOpen] = useState(false);
  const [itemDropdownFilter, setItemDropdownFilter] = useState();

  //Modal
  const [showModal, setShowModal] = useState(false);
  const [detailReservasi, setDetailReservasi] = useState([]);
  const [detailTransaksi, setDetailTransaksi] = useState([]);
  const [showModalCancel, setShowModalCancel] = useState(false);

  //Filter Range Date
  const [showModalRangeDates, setShowModalRangeDate] = useState(false);
  const [showModalRangeDateTransaction, setShowModalRangeDateTransaction] =
    useState(false);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [dateStartTransaction, setDateStartTransaction] = useState("");
  const [dateEndTransaction, setDateEndTransaction] = useState("");

  //Card Reservasi
  const [reservasiSukses, setReservasiSukses] = useState(0);
  const [reservasiBatal, setReservasiBatal] = useState(0);
  const [reservasiIndividu, setReservasiIndividu] = useState(0);
  const [reservasiGroup, setReservasiGroup] = useState(0);

  //type
  const [reservationType, setReservationType] = useState(false);

  //UseEffect Card Reservasi
  useEffect(() => {
    const dataReservasiSukses = reservation.filter(
      (item) => item.state === "success"
    ).length;
    const dataReservasiBatal = reservation.filter(
      (item) => item.state === "cancel"
    ).length;
    const dataReservasiIndividu = reservation.filter(
      (item) => item.reservation_type === "individual"
    ).length;
    const dataReservasiGroup = reservation.filter(
      (item) => item.reservation_type === "group"
    ).length;

    setReservasiBatal(dataReservasiBatal);
    setReservasiSukses(dataReservasiSukses);
    setReservasiIndividu(dataReservasiIndividu);
    setReservasiGroup(dataReservasiGroup);
  }, [
    reservation,
    reservasiSukses,
    reservasiBatal,
    reservasiIndividu,
    reservasiGroup,
  ]);

  useEffect(() => {
    const datepickerEl = document?.getElementById("datepickerStart");
    const datepickerE2 = document?.getElementById("datepickerStart1");
    new Datepicker(datepickerEl, {});
    new Datepicker(datepickerE2, {});
  }, []);

  useEffect(() => {
    const datepickerEl = document?.getElementById("datepickerEnd");
    const datepickerE2 = document?.getElementById("datepickerEnd1");
    new Datepicker(datepickerEl, {});
    new Datepicker(datepickerE2, {});
  }, []);

  const showDateRange = () => {
    setShowModalRangeDateTransaction(false);
    setShowModalRangeDate(!showModalRangeDates);
    setShowFilterOptions(!showFilterOptions);
  };

  const showDateTransactionRange = () => {
    setShowModalRangeDateTransaction(!showModalRangeDateTransaction);
    setShowFilterOptions(!showFilterOptions);
    setShowModalRangeDate(false);
  };

  const handleDateStart = (selectDate) => {
    const parsedDate = new Date(selectDate);

    if (!isNaN(parsedDate.getTime())) {
      const formattedDate = `${parsedDate.getFullYear()}-${String(
        parsedDate.getMonth() + 1
      ).padStart(2, "0")}-${String(parsedDate.getDate()).padStart(2, "0")}`;

      setDateStart(formattedDate);
      console.log(formattedDate);
    } else {
      console.error("Invalid date format");
    }
  };

  const handleDateEnd = (selectDate) => {
    const parsedDate = new Date(selectDate);
    if (!isNaN(parsedDate.getTime())) {
      const formattedDate = `${parsedDate.getFullYear()}-${String(
        parsedDate.getMonth() + 1
      ).padStart(2, "0")}-${String(parsedDate.getDate()).padStart(2, "0")}`;

      setDateEnd(formattedDate);
      console.log(formattedDate);
    } else {
      console.error("Invalid date format");
    }
  };

  const handleDateStartTransaction = (selectDate) => {
    const parsedDate = new Date(selectDate);

    if (!isNaN(parsedDate.getTime())) {
      const formattedDate = `${parsedDate.getFullYear()}-${String(
        parsedDate.getMonth() + 1
      ).padStart(2, "0")}-${String(parsedDate.getDate()).padStart(2, "0")}`;

      setDateStartTransaction(formattedDate);
      console.log(formattedDate);
    } else {
      console.error("Invalid date format");
    }
  };

  const handleDateEndTransaction = (selectDate) => {
    const parsedDate = new Date(selectDate);
    if (!isNaN(parsedDate.getTime())) {
      const formattedDate = `${parsedDate.getFullYear()}-${String(
        parsedDate.getMonth() + 1
      ).padStart(2, "0")}-${String(parsedDate.getDate()).padStart(2, "0")}`;

      setDateEndTransaction(formattedDate);
      console.log(formattedDate);
    } else {
      console.error("Invalid date format");
    }
  };

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  //Filter Search
  const handleDropdowFilter = () => {
    setDropdownOpen(!dropdownFilter);
    console.log(dropdownFilter);
  };

  //Modal Active
  const handdleModalActive = async (e) => {
    const idReservasi = e.currentTarget.getAttribute("data-reservation-id");
    try {
      const response = await axios.get(
        `${API_URLS.reservasi}/api/get_partner_reservation_detail?reservation_id=${idReservasi}`
      );
      console.log(idReservasi);

      setDetailReservasi(response.data.partner_reservation);
      setDetailTransaksi(response.data.partner_reservation_detail);
      if (response.data.length === 0) {
        Swal.fire({
          title: "Oops!",
          text: "Data Detail Reservasi Tidak Ada",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      } else {
        setShowModal(!showModal);
      }
    } catch (error) {
      console.error("Error fetching reservation details:", error);
    }
  };

  //Modal Cancel Active
  const handleModalCancel = (e) => {
    const reservationId = e.currentTarget.getAttribute("data-reservation-id");
    const datePlan = e.currentTarget.getAttribute("data-date-plan");
    setTanggalKedatangan(datePlan);
    setPartnerResvId(reservationId);
    setShowModalCancel(!showModalCancel);
    setShowModal(false);
  };
  const handdleCancelReservasi = async (e) => {
    try {
      let datePlan = moment(tanggalKedatangan).format("YYYY-MM-DD");
      console.log(datePlan);
      if (dateToday >= datePlan) {
        setShowModalCancel(!showModalCancel);
        Swal.fire({
          title: "Oops!",
          text: "Pembatalan Reservasi Minimal 1 Hari Sebelum Tanggal Kedatangan.",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      } else {
        console.log("success");
        const formData = new FormData();
        formData.append("partner_id", partnerId);
        formData.append("partner_reservation_id", partnerResvId);

        setShowModalCancel(!showModalCancel);

        Swal.fire({
          title: "Loading!",
          text: "Proses Pembatalan Reservasi",
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const response = await axios.post(
          `${API_URLS.reservasi}/api/cancel_partner_reservation`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer YOUR_ACCESS_TOKEN",
            },
          }
        );

        if (response.data.success === "Data Berhasil di Cancel") {
          Swal.close();
          Swal.fire({
            title: "Sukses",
            text: "Reservasi Berhasil di Batalkan",
            imageUrl: "/img/STK-20230906-WA0025.webp",
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: "Custom image",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else if (response.data.error === "Tiket Sudah Dipakai") {
          Swal.close();
          Swal.fire({
            title: "Oops!",
            text: "Tiket sudah dipakai, tidak bisa dibatalkan.",
            imageUrl: "/img/STK-20230906-WA0006.webp",
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: "Custom image",
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        } else if (response.data.error === "Tiket Sudah Dibatalkan") {
          Swal.close();
          Swal.fire({
            title: "Oops!",
            text: "Reservasi sudah dibatalkan sebelumnya",
            imageUrl: "/img/STK-20230906-WA0006.webp",
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: "Custom image",
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        } else if (response.status === 200) {
          Swal.close();
          Swal.fire({
            title: "Sukses",
            text: "Reservasi Berhasil di Batalkan",
            imageUrl: "/img/STK-20230906-WA0025.webp",
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: "Custom image",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          console.log(response);
          Swal.close();
          Swal.fire({
            title: "Oops!",
            text: "Reservasi Gagal di Batalkan, Silahkan Coba Lagi",
            imageUrl: "/img/STK-20230906-WA0006.webp",
            imageWidth: 200,
            imageHeight: 200,
            imageAlt: "Custom image",
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      }
    } catch (error) {
      Swal.close();
      console.error("Error fetching reservation details:", error);
    }
  };

  const handleFilter = (e) => {
    const value = e.target.getAttribute("filter");
    if (value == activeFilter) {
      setActiveFilter("");
    } else {
      setActiveFilter(value);
    }
    setShowFilterOptions(!showFilterOptions);
  };

  const handleType = () => {
    if (reservationType === true) {
      setActiveFilter("");
    }
    setReservationType(!reservationType);
  };

  //Filter Search
  const handleItemDropdownFilter = (e) => {
    const value = e.currentTarget.getAttribute("filter");
    setItemDropdownFilter(value);
    setDropdownOpen(!dropdownFilter);
  };

  const paid =
    activeFilter === "paid"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";
  const arrival_today =
    activeFilter === "arrival_today"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";
  const cancel =
    activeFilter === "cancel"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";
  const type =
    reservationType === true ||
    activeFilter === "individu" ||
    activeFilter === "group"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";
  const individu =
    activeFilter === "individu"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";
  const group =
    activeFilter === "group"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";
  const range_date =
    showModalRangeDates === true
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";

  const range_transaction =
    showModalRangeDateTransaction === true
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";

  React.useEffect(() => {
    axios
      .get(
        API_URLS.reservasi +
          "/api/get_partner_reservation?partner_id=" +
          partnerId +
          "&customer_name=" +
          customerName +
          "&filter=" +
          activeFilter +
          "&page=" +
          pageNumber +
          `${
            itemDropdownFilter === "order_id" ? "&order_id=" + customerName : ""
          }` +
          `${showModalRangeDates === true ? "&date_start=" + dateStart : ""}` +
          `${showModalRangeDates === true ? "&date_end=" + dateEnd : ""}` +
          `${
            showModalRangeDateTransaction === true
              ? "&transaction_start=" + dateStartTransaction
              : ""
          }` +
          `${
            showModalRangeDateTransaction === true
              ? "&transaction_end=" + dateEndTransaction
              : ""
          }` +
          `${
            reservationType === true ? "&reservation_type=" + activeFilter : ""
          }`
      )
      .then((response) => {
        let reservations = response.data.partner_reservations.data;
        setFromItems(response.data.partner_reservations.from);
        setToItems(response.data.partner_reservations.to);
        let newReservation = [];
        for (let index = 0; index < reservations.length; index++) {
          newReservation.push({
            reservation_id: reservations[index].id,
            order_id: reservations[index].order_id,
            customer: reservations[index].partner_customer,
            total_ticket: reservations[index].quantity,
            amount_total: reservations[index].amount_total,
            date_plan: reservations[index].date_plan,
            date_order: reservations[index].created_at,
            booking_code: reservations[index].booking_code,
            reservation_type: reservations[index].reservation_type,
            state: reservations[index].state,
            detail: reservations[index].reservation_detail,
          });
        }
        setReservation(newReservation);
        setCurentPage(response.data.partner_reservations.current_page);
        setTotalPage(response.data.partner_reservations.last_page);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    partnerId,
    customerName,
    activeFilter,
    pageNumber,
    dateStart,
    dateEnd,
    showModalRangeDates,
    showModalRangeDateTransaction,
    dateStartTransaction,
    dateEndTransaction,
  ]);

  useEffect(() => {
    console.log(detailReservasi);
    console.log(detailTransaksi);
    console.log(reservation);
  }, [detailReservasi, detailTransaksi, reservation]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    console.log(dateToday);
  }, [dateToday]);

  return (
    <>
      <LayoutMain>
        <div className="w-full px-5 md:px-10 pt-6 ">
          <strong className="inline-block mt-2 text-xl text-sekunder-60">
            Reservasi
          </strong>
          <div className="max-w-full w-full py-6 rounded-lg">
            <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4 pb-9">
              <div className="w-full">
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
                  <Card className="w-full min-h-fit p-4">
                    <div className="w-full flex items-center">
                      <div className="w-fit flex ">
                        <FaChartPie className="text-6xl text-sky-600" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Total
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {reservation.length}
                        </strong>
                      </div>
                    </div>
                  </Card>
                  <Card className="w-full min-h-fit p-4">
                    <div className="w-full flex items-center">
                      <div className="w-fit flex ">
                        <FaCircleCheck className="text-6xl text-emerald-500" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Berhasil
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {reservasiSukses}
                        </strong>
                      </div>
                    </div>
                  </Card>
                  <Card className="w-full min-h-fit p-4">
                    <div className="w-full flex items-center">
                      <div className="w-fit flex ">
                        <FaCircleXmark className="text-6xl text-rose-500" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Dibatalkan
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {reservasiBatal}
                        </strong>
                      </div>
                    </div>
                  </Card>
                  <Card className="w-full min-h-fit p-4">
                    <div className="w-full flex items-center">
                      <div className="w-fit flex ">
                        <FaUserLarge className="text-5xl text-cyan-600" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Individu
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {reservasiIndividu}
                        </strong>
                      </div>
                    </div>
                  </Card>
                  <Card className="w-full min-h-fit p-4">
                    <div className="w-full flex items-center">
                      <div className="w-fit flex ">
                        <FaUserGroup className="text-6xl text-amber-500" />
                      </div>
                      <div className="w-full grid grid-cols-1 items-start py-4 px-4  gap-0">
                        <span className="text-md text-slate-600 whitespace-nowrap">
                          Group
                        </span>
                        <strong className="text-xl text-slate-800 whitespace-nowrap">
                          {reservasiGroup}
                        </strong>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            <span className="inline-block font-semibold whitespace-nowrap">
              Daftar Reservasi
            </span>
            <div
              className={`${
                showModalRangeDates ? "flex" : "hidden"
              } w-full flex-col md:flex-row md:items-center gap-6 my-6`}
            >
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <input
                  id="datepickerStart"
                  type="text"
                  onSelect={(e) => handleDateStart(e.target.value)}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-informasi-40 focus:border-informasi-60 block w-full ps-10 p-3  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-informasi-40 dark:focus:border-informasi-60"
                  placeholder="Awal Tanggal Kedatangan"
                />
              </div>
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <input
                  id="datepickerEnd"
                  type="text"
                  onSelect={(e) => handleDateEnd(e.target.value)}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-informasi-40 focus:border-informasi-60 block w-full ps-10 p-3  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-informasi-40 dark:focus:border-informasi-60"
                  placeholder="Akhir Tanggal Kedatangan"
                />
              </div>
            </div>
            <div
              className={`${
                showModalRangeDateTransaction ? "flex" : "hidden"
              } w-full flex-col md:flex-row md:items-center gap-6 my-6`}
              datepicker
              datepicker-format="dd/mm/yyyy"
            >
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <input
                  id="datepickerStart1"
                  type="text"
                  onSelect={(e) => handleDateStartTransaction(e.target.value)}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-informasi-40 focus:border-informasi-60 block w-full ps-10 p-3  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-informasi-40 dark:focus:border-informasi-60"
                  placeholder="Awal Tanggal Transaksi"
                />
              </div>
              <div className="relative w-full">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </div>
                <input
                  datepicker-format="yyyy-mm-dd"
                  id="datepickerEnd1"
                  type="text"
                  onSelect={(e) => handleDateEndTransaction(e.target.value)}
                  className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-informasi-40 focus:border-informasi-60 block w-full ps-10 p-3  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-informasi-40 dark:focus:border-informasi-60"
                  placeholder="Akhir Tanggal Transaksi"
                />
              </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4 py-2 mt-4">
              <div className="w-full flex flex-col lg:flex-row items-center gap-4">
                <div className="w-full lg:w-1/2 flex items-center">
                  <div className="w-full relative">
                    <input
                      onInput={(e) => {
                        setCustomerName(e.target.value);
                      }}
                      type="text"
                      id="serach-tabel"
                      placeholder={
                        itemDropdownFilter === "order_id"
                          ? "Cari Order ID"
                          : "Cari Nama Pelanggan"
                      }
                      className="w-full px-10 py-3 border border-slate-300 bg-white dark:bg-slate-600 rounded-full focus:outline-none focus:border-primer-60 text-slate-800 dark:text-slate-50 text-sm focus:ring-2 focus:ring-primer-40"
                    />
                    <HiMiniMagnifyingGlass className="absolute top-3 left-3.5 text-xl" />
                    <div className="absolute right-3 top-3.5">
                      <div className="relative">
                        <button
                          className="w-fit flex items-center gap-2 text-sm"
                          onClick={handleDropdowFilter}
                        >
                          Filter Pencarian
                          <FaAngleDown />
                        </button>
                        <ul
                          className={`${
                            dropdownFilter === true ? "" : "hidden"
                          } absolute top-5 left-0 bg-white py-1 border rounded-lg shadow mt-4 z-10`}
                        >
                          <li
                            className={`w-full px-4 py-2 my-2 text-sm cursor-pointer ${
                              itemDropdownFilter === "nama-pelanggan"
                                ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
                                : "hover:bg-slate-200 text-slate-950"
                            }`}
                          >
                            <a
                              className="flex items-center gap-2 text-sm whitespace-nowrap"
                              onClick={(e) => handleItemDropdownFilter(e)}
                              filter="nama-pelanggan"
                            >
                              <FaCircleCheck
                                className={`${
                                  itemDropdownFilter === "nama-pelanggan"
                                    ? "block"
                                    : "hidden"
                                }`}
                              />
                              Nama Pelanggan
                            </a>
                          </li>
                          <li
                            className={`w-full px-4 py-2 my-2 text-sm cursor-pointer ${
                              itemDropdownFilter === "order_id"
                                ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
                                : "hover:bg-slate-200 text-slate-950"
                            }`}
                          >
                            <a
                              className="flex items-center gap-2 text-sm whitespace-nowrap"
                              onClick={(e) => handleItemDropdownFilter(e)}
                              filter="order_id"
                            >
                              <FaCircleCheck
                                className={`${
                                  itemDropdownFilter === "order_id"
                                    ? "block"
                                    : "hidden"
                                }`}
                              />
                              ID Order
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex items-center">
                  <div className="relative">
                    <button
                      id="filterButton"
                      type="button"
                      className="px-4 py-3 w-full lg:w-fit flex items-center justify-center gap-2 bg-sekunder-60 font-semibold text-white text-sm rounded-full hover:bg-sekunder-40 transition-colors duration-200 ease-in-out"
                      onClick={toggleFilterOptions}
                    >
                      <FaFilter className="text-sm" />
                      <span className="inline-block font-semibold whitespace-nowrap">
                        Filter
                      </span>
                    </button>

                    {showFilterOptions && (
                      <ul className="absolute top-10 left-0 bg-white py-1 border rounded-lg shadow mt-4 z-10">
                        <li
                          className={`w-full px-4 py-2  text-sm cursor-pointer my-1 ${paid}`}
                        >
                          <a
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            onClick={(e) => handleFilter(e)}
                            filter="paid"
                          >
                            <FaCircleCheck
                              className={`${
                                activeFilter === "paid" ? "block" : "hidden"
                              }`}
                            />
                            Berhasil
                          </a>
                        </li>
                        <li
                          className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${arrival_today}`}
                        >
                          <a
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            onClick={(e) => handleFilter(e)}
                            filter="arrival_today"
                          >
                            <FaCircleCheck
                              className={`${
                                activeFilter === "arrival_today"
                                  ? "block"
                                  : "hidden"
                              }`}
                            />
                            Kedatangan Hari Ini
                          </a>
                        </li>
                        <li
                          className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${cancel}`}
                        >
                          <a
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            onClick={(e) => handleFilter(e)}
                            filter="cancel"
                          >
                            <FaCircleCheck
                              className={`${
                                activeFilter === "cancel" ? "block" : "hidden"
                              }`}
                            />
                            Dibatalkan
                          </a>
                        </li>
                        <li
                          className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${type}`}
                        >
                          <a
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            onClick={handleType}
                            filter="type"
                          >
                            <FaCircleCheck
                              className={`${
                                reservationType ||
                                activeFilter === "individu" ||
                                activeFilter === "group"
                                  ? "block"
                                  : "hidden"
                              }`}
                            />
                            Jenis Transaksi
                          </a>
                        </li>
                        {reservationType ? (
                          <ul className="w-full px-4 py-2 dark:hover:bg-slate-500 bg-white cursor-pointer my-1 ">
                            <li
                              className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${individu}`}
                            >
                              <a
                                className="flex items-center gap-2 text-sm whitespace-nowrap"
                                onClick={(e) => handleFilter(e)}
                                filter="individu"
                              >
                                <FaCircleCheck
                                  className={`${
                                    activeFilter === "individu"
                                      ? "block"
                                      : "hidden"
                                  }
                                    `}
                                />
                                Individu
                              </a>
                            </li>
                            <li
                              className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${group}`}
                            >
                              <a
                                className="flex items-center gap-2 text-sm whitespace-nowrap"
                                onClick={(e) => handleFilter(e)}
                                filter="group"
                              >
                                <FaCircleCheck
                                  className={`${
                                    activeFilter === "group"
                                      ? "block"
                                      : "hidden"
                                  }
                                    `}
                                />
                                Rombongan
                              </a>
                            </li>
                          </ul>
                        ) : null}
                        <li
                          className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${range_date}`}
                        >
                          <a
                            onClick={(e) => {
                              showDateRange();
                              handleFilter(e);
                            }}
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            filter="range_date"
                          >
                            <FaCircleCheck
                              className={`${
                                showModalRangeDates === true &&
                                activeFilter === "range_date"
                                  ? "block"
                                  : "hidden"
                              }`}
                            />
                            Cari Kedatangan
                          </a>
                        </li>
                        <li
                          className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${range_transaction}`}
                        >
                          <a
                            onClick={(e) => {
                              showDateTransactionRange();
                              handleFilter(e);
                            }}
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            filter="range_transaction"
                          >
                            <FaCircleCheck
                              className={`${
                                showModalRangeDateTransaction === true &&
                                activeFilter === "range_transaction"
                                  ? "block"
                                  : "hidden"
                              }`}
                            />
                            Cari Tanggal Transaksi
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <NavLink
                to={statusPartner == 1 ? "#" : "/reservasi/create-reservasi"}
                className={`w-fit px-4 py-3 rounded-full flex items-center justify-center gap-2  font-semibold text-white text-sm  transition-colors duration-200 ease-in-out whitespace-nowrap ${
                  statusPartner == 1
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-primer-60 hover:bg-primer-80 cursor-pointer"
                }`}
              >
                <HiPlus className="text-base" />
                Create Reservation
              </NavLink>
            </div>
            <div className="w-full py-4">
              {/* <!-- Desktop View Start --> */}
              <div className="hidden xl:block overflow-auto">
                <table className="w-full">
                  <thead className="bg-slate-100 rounded-lg">
                    <tr>
                      <th className="pl-6 pr-3 py-3 text-sm font-semibold tracking-wide text-left">
                        No
                      </th>
                      <th className="pl-3 pr-3 py-3 text-sm font-semibold tracking-wide text-left">
                        ID Pesanan
                      </th>
                      <th className="pl-3 pr-3 py-3 text-sm font-semibold tracking-wide text-left">
                        Kode Booking
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Pelanggan
                      </th>
                      {/* <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Tanggal Pemesanan
                      </th> */}
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Tanggal Kedatangan
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Jumlah Tiket
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Tagihan
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Total Insentive
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Status
                      </th>
                      <th className="pr-6 pl-3 py-3 text-sm font-semibold tracking-wide text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y-2 divide-slate-100 dark:divide-slate-600">
                    {reservation.map((reservation, index) => {
                      let totalRevenue;
                      let detail = reservation.detail;

                      detail.forEach((detail) => {
                        let totaltagihan = detail.quantity * detail.price_unit;
                        let totalgross = detail.quantity * detail.price_adjust;
                        totalRevenue = totalgross - totaltagihan;
                      });

                      orderDate = new Date(reservation.date_order);
                      formattedorderDate = format(
                        orderDate,
                        "eeee, dd MMMM yyyy HH:mm:ss",
                        { locale: id }
                      );
                      parsedDate = parseISO(reservation.date_plan);
                      formattedDate = format(parsedDate, "EEEE, dd MMMM yyyy", {
                        locale: id,
                      });
                      return (
                        <tr className="bg-white" key={index}>
                          <td className="pl-6 pr-3 py-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {fromItems + index}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {reservation.order_id}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {reservation.booking_code}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {reservation.customer.partner_customer_name}
                          </td>
                          {/* <td className="p-3 text-sm tracking-wide text-left">
                            {formattedorderDate}
                          </td> */}
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {formattedDate}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {reservation.total_ticket}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {formatRupiah(reservation.amount_total)}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {formatRupiah(reservation.total_ticket * 5000)}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {reservation.state === "success" ? (
                              <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                Sukses
                              </span>
                            ) : (
                              <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                Dibatalkan
                              </span>
                            )}
                          </td>
                          <td className="pr-6 pl-3 py-3 text-sm tracking-wide text-left">
                            <div className="w-fit flex item-center gap-2 px-3 py-2 justify-between">
                              <button
                                onClick={(e) => handleModalCancel(e)}
                                className="w-8 h-8 flex items-center justify-center rounded-lg bg-rose-50 text-rose-600 hover:bg-rose-600 hover:text-white transition-colors duration-200 ease-in-out"
                                title="batalkan pesanan"
                                data-reservation-id={reservation.reservation_id}
                                data-date-plan={reservation.date_plan}
                              >
                                <FaCircleXmark />
                              </button>
                              <button
                                onClick={(e) => handdleModalActive(e)}
                                className="w-8 h-8 flex items-center justify-center rounded-lg bg-amber-50 text-amber-600 hover:bg-amber-600 hover:text-white transition-colors duration-200 ease-in-out cursor-pointer"
                                title="detail"
                                data-reservation-id={reservation.reservation_id}
                              >
                                <FaRegEye />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <!-- Desktop View End --> */}

              {/* <!-- Mobile View Start --> */}
              <div className="xl:hidden w-full">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {reservation.map((reservation, index) => {
                      orderDate = new Date(reservation.date_order);
                      formattedorderDate = format(
                        orderDate,
                        "eeee, dd MMMM yyyy HH:mm:ss",
                        { locale: id }
                      );
                      parsedDate = parseISO(reservation.date_plan);
                      formattedDate = format(parsedDate, "EEEE, dd MMMM yyyy", {
                        locale: id,
                      });
                      return (
                        <tr key={reservation.order_id}>
                          <td
                            onClick={(e) => handdleModalActive(e)}
                            data-reservation-id={reservation.reservation_id}
                          >
                            <Card className="w-full px-4 py-3 flex flex-col gap-3 border border-slate-300 rounded-lg my-2 cursor-pointer">
                              <div className="w-full flex items-center justify-between">
                                <strong className="text-base text-left">
                                  {reservation.customer.partner_customer_name}
                                </strong>
                                <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 whitespace-nowrap">
                                  {reservation.order_id}
                                </span>
                              </div>
                              <div className="w-full flex flex-col gap-2">
                                <div className="w-full flex item-center gap-2">
                                  <FaCalendarCheck className="text-lg" />
                                  <span className="text-left">
                                    {formattedDate}
                                  </span>
                                </div>
                                <div className="w-full flex item-center gap-2">
                                  <FaCalendarDay className="text-lg" />
                                  <span className="text-left">
                                    {formattedorderDate}
                                  </span>
                                </div>
                                <div className="w-full flex items-center justify-between py-2">
                                  <span className="text-sm text-left">
                                    {reservation.total_ticket} Tiket
                                  </span>
                                  <strong>
                                    {formatRupiah(reservation.amount_total)}
                                  </strong>
                                </div>
                                {reservation.state === "success" ? (
                                  <div className="w-fit flex item-center gap-2 px-3 py-2 rounded-full bg-green-50">
                                    <FaCircle className="text-green-600 text-sm" />
                                    <span className="font-semibold whitespace-nowrap text-green-600 text-xs uppercase">
                                      Sukses
                                    </span>
                                  </div>
                                ) : (
                                  <div className="w-fit flex item-center gap-2 px-3 py-2 rounded-full bg-red-50">
                                    <FaCircle className="text-red-600 text-sm" />
                                    <span className="font-semibold whitespace-nowrap text-red-600 text-xs uppercase">
                                      Dibatalkan
                                    </span>
                                  </div>
                                )}
                              </div>
                            </Card>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <!-- Mobile View End --> */}
            </div>
            {/* <!-- Pagination Start --> */}
            <div className="max-w-full w-full pagination bg-white dark:bg-slate-800 px-6 py-4 rounded-b-lg">
              <ul className="flex items-center justify-end">
                <span
                  id="pagination-info"
                  className="block mr-4 text-sm text-slate-600 dark:text-slate-300"
                >
                  Showing page {currentPage} of {totalPage}
                </span>
                <li>
                  <button
                    onClick={() => setpageNumber(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`flex items-center justify-center h-10 w-10 mr-2 rounded-lg ${
                      currentPage === 1
                        ? "bg-slate-300 text-slate-600 cursor-not-allowed"
                        : "bg-primer-20 text-primer-60 hover:bg-primer-60 t hover:text-white cursor-pointer"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setpageNumber(currentPage + 1)}
                    disabled={currentPage === totalPage}
                    className={`flex items-center justify-center h-10 w-10 mr-2 rounded-lg ${
                      currentPage === totalPage
                        ? "bg-slate-300 text-slate-600 cursor-not-allowed"
                        : "bg-primer-20 text-primer-60 hover:bg-primer-60 t hover:text-white cursor-pointer"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
            {/* <!-- Pagination End --> */}
          </div>
          <div className="px-10 lg:pl-10 lg:pr-16 py-4 border-t-2 border-t-slate-100 dark:border-t-slate-700">
            <p className="text-sm text-slate-400">
              Copyright © 2023 Aplikasi By IT SALOKA
            </p>
          </div>
        </div>
      </LayoutMain>
      <section
        className={`modal-container ${
          showModal ? "flex" : "hidden"
        } items-center justify-center max-w-full w-full max-h-screen h-full fixed top-0 left-0 z-[9999] px-5 py-5`}
      >
        <div
          className={`show-modal w-full max-w-full xl:max-w-7xl max-h-screen lg:max-h-fit h-full lg:h-fit overflow-auto rounded-lg bg-white dark:bg-slate-800 ${
            showModal ? "active" : ""
          }`}
        >
          <div className="w-full flex items-center justify-between relative p-4 lg:p-6 border-b border-slate-100 dark:border-slate-600">
            <strong>Detail Reservasi</strong>
            <button
              className="cursor-pointer"
              onClick={() => setShowModal(false)}
            >
              <FaCircleXmark className="text-2xl" />
            </button>
          </div>
          <div className="w-full px-6 py-6 lg:px-6 lg:pt-6 lg:pb-9">
            <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="w-full lg:col-span-2 h-fit bg-white dark:bg-slate-800 rounded-lg border border-slate-200 dark:border-slate-600">
                <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 px-6 py-4 border-b border-slate-200 dark:border-slate-700">
                  <span className="inline-block font-semibold text-sm">
                    Detail Customer
                  </span>
                </div>
                <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-6 px-5 pb-6 pt-4">
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                      Nama Customer
                    </span>
                    <span className="text-[13px] whitespace-nowrap">
                      {
                        detailTransaksi[0]?.partner_customer
                          ?.partner_customer_name
                      }
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                      Nomor Telphone
                    </span>
                    <span className="text-[13px] whitespace-nowrap">
                      {
                        detailTransaksi[0]?.partner_customer
                          ?.partner_customer_phone
                      }
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                      Email
                    </span>
                    <span className="text-[13px] whitespace-nowrap">
                      {
                        detailTransaksi[0]?.partner_customer
                          ?.partner_customer_email
                      }
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                      Provinsi
                    </span>
                    <span className="text-[13px] whitespace-nowrap">
                      {
                        detailTransaksi[0]?.partner_customer
                          ?.partner_customer_province
                      }
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                      Kota/Kabupaten
                    </span>
                    <span className="text-[13px] whitespace-nowrap">
                      {
                        detailTransaksi[0]?.partner_customer
                          ?.partner_customer_city
                      }
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                      Tanggal Pemesanan
                    </span>
                    <span className="text-[13px]">
                      {detailReservasi.created_at
                        ? formatDate(detailReservasi.created_at)
                        : "Tanggal tidak valid"}
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                      Tanggal Kedatangan
                    </span>
                    <span className="text-[13px] whitespace-nowrap">
                      {detailReservasi.date_plan
                        ? formatDate(detailReservasi.date_plan)
                        : "Tanggal tidak valid"}
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                      Tipe Reservasi
                    </span>
                    <span className="text-[13px] whitespace-nowrap">
                      {detailReservasi.reservation_type == "individual"
                        ? "Individu"
                        : "Rombongan"}
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                      Total Bayar
                    </span>
                    <span className="text-[13px] ">
                      {formatRupiah(detailReservasi.amount_total)}
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                      Total Tiket
                    </span>
                    <span id="qty-total" className="text-[13px]">
                      {detailReservasi.quantity}
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                      Kode Booking
                    </span>
                    <span id="kode-booking" className="text-[13px] ">
                      {detailReservasi.booking_code}
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                      Order ID
                    </span>
                    <span id="order-id" className="text-[13px] ">
                      {detailReservasi.order_id}
                    </span>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                      Status
                    </span>
                    <div className="flex items-center gap-2">
                      {detailReservasi.state === "success" ? (
                        <>
                          <FaCircleCheck className="text-lg text-emerald-500" />
                          <span className="text-emerald-500 text-sm font-semibold">
                            {" "}
                            Sukses{" "}
                          </span>
                        </>
                      ) : (
                        <>
                          <FaCircleXmark className="text-lg text-rose-600" />
                          <span className="text-rose-600 text-sm font-semibold">
                            {" "}
                            Cancel/Dibatalkan{" "}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                      Cancel Reservasi
                    </span>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={(e) => handleModalCancel(e)}
                        type="button"
                        className="w-fit text-white bg-rose-700 hover:bg-rose-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center dark:bg-rose-600 dark:hover:bg-rose-700"
                        data-reservasi-id={detailReservasi.id}
                      >
                        <FaCircleXmark className="text-lg" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-fit lg:h-full bg-white dark:bg-slate-800 rounded-lg border border-slate-200 dark:border-slate-600">
                <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between gap-3 px-6 py-4 border-b border-slate-200 dark:border-slate-700">
                  <span className="inline-block font-semibold text-sm">
                    Detail Transaksi
                  </span>
                </div>
                <div className="w-full px-5 pb-6 pt-4">
                  {detailTransaksi.map((item, index) => (
                    <div
                      key={index}
                      className="w-full flex flex-row gap-4 py-3"
                    >
                      <div className="w-14 h-14 rounded-full">
                        <img
                          src="/img/koplemen100.svg"
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="flex flex-col items-start gap-2">
                        <div className="w-full flex flex-col gap-1">
                          <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                            Nama Tiket
                          </span>
                          <span className="text-[12px] whitespace-nowrap">
                            {item.partner_ticket_name} ({item.quantity} pax)
                          </span>
                        </div>
                        {/* <span className="text-[13px]">
                          Jumlah Tiket
                        </span>
                        <span className="text-[13px] whitespace-nowrap">
                        {item.quantity} 
                          </span> */}
                        <div className="w-full flex flex-col gap-1">
                          <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                            Harga Normal
                          </span>
                          <span className="text-[12px] whitespace-nowrap">
                            {formatRupiah(item.price_unit)}
                          </span>
                        </div>
                        <div className="w-full flex flex-col gap-1">
                          <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                            Harga Jual
                          </span>
                          <span className="text-[12px] whitespace-nowrap">
                            {formatRupiah(item.price_adjust)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className={`modal-container ${
          showModalCancel ? "flex" : "hidden"
        } items-center justify-center max-w-full w-full max-h-screen h-full fixed top-0 left-0 z-[9999] px-5`}
      >
        <div
          className={`show-modal w-full max-w-full md:max-w-lg rounded-lg bg-white dark:bg-slate-700 p-4 ${
            showModalCancel ? "active" : " "
          }`}
        >
          <div className="w-full flex items-center justify-between relative pb-6 ">
            <strong></strong>
            <button onClick={() => setShowModalCancel(false)}>
              <FaCircleXmark className="text-2xl" />
            </button>
          </div>
          <div className="w-full flex flex-col items-center justify-center gap-2 pt-6">
            <div className="w-48 h-48 overflow-hidden">
              <img
                src="/img/STK-20230906-WA0006.webp"
                alt="gambar loka"
                className="w-full h-full object-cover"
              />
            </div>
            <strong className="text-center text-lg">
              Membatalkan Reservasi
            </strong>
            <span className="text-center text-sm">
              Apakah Anda Sudah Yakin Ingin Membatalkan Reservasi Ini ?
            </span>
          </div>
          <div className="w-full flex item-center justify-end gap-3 mt-6">
            <button
              className="close-modal px-4 py-3 rounded-lg bg-slate-300 text-slate-950 text-sm hover:bg-slate-200 w-fit"
              onClick={() => setShowModalCancel(false)}
            >
              Batal
            </button>
            <button
              id="upload-file"
              type="submit"
              form="form_data"
              className="close-modal px-4 py-3 rounded-lg text-slate-50 text-sm bg-primer-60 hover:bg-primer-80 transition-colors duration-200 ease-in-out w-fit"
              onClick={handdleCancelReservasi}
            >
              Batalkan Reservasi
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Reservasi;
