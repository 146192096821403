import { NavLink } from "react-router-dom";

const ModalKonfirmasi = ({ isOpen, onClose, titleBtnOK, titleBtnCancel, img, title, desc, actionClick}) => {
    const containerOverlay = isOpen ? 'modal-container flex items-center justify-center max-w-full w-full max-h-screen h-full fixed top-0 left-0 z-[9999] px-5' : 'hidden';
    const modalClass = isOpen ? 'show-modal block w-full max-w-full md:max-w-2xl rounded-xl bg-white dark:bg-slate-800 p-4' : 'hidden';
    return (
        <section className={containerOverlay}>
            <div className={modalClass}>
                <div className="w-full flex flex-col items-center justify-center gap-4">
                    <div className="w-48 h-48 overflow-hidden">
                        <img src={img} alt="Gambar" />
                    </div>
                    <strong className="text-xl">{title}</strong>
                    <span className="text-center">{desc}</span>
                </div>
                <div className="w-full flex items-center justify-end gap-4 mt-9">
                <button onClick={actionClick} className="px-4 py-3 rounded-full text-slate-50 font-semibold text-xs bg-primer-60 hover:bg-primer-80 transition-colors duration-200 ease-in-out w-fit">
                        {titleBtnOK}
                    </button>
                    <button onClick={onClose} className="px-4 py-3 rounded-full font-semibold bg-slate-300 text-slate-950 text-xs hover:bg-slate-200 w-fit">
                        {titleBtnCancel}
                    </button>
                </div>
            </div>
        </section>
    )
}

export default ModalKonfirmasi;