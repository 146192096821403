import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card } from "react-rainbow-components";
import LayoutMain from "../../layouts/layout-main";
import { HiMiniMagnifyingGlass, HiPlus } from "react-icons/hi2";
import { FaFilter } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import API_URLS from "../../config";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { id } from "date-fns/locale";
import { FaCalendarCheck, FaCalendarDay } from "react-icons/fa";
import CryptoJS from "crypto-js";
import config from "../../config";
import { formatRupiah } from "../utilities/FormatRupiah";
import { FileInput, Label } from "flowbite-react";
import {
  FaCircleXmark,
  FaRegEye,
  FaAngleDown,
  FaCircleCheck,
} from "react-icons/fa6";
import Swal from "sweetalert2";

const Insentif = () => {
  useEffect(() => {
    document.title = "Reservasi - Partnership Saloka Park";
  }, []);
  const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  const getUsernameFromLocalStorage = (secretKey) => {
    const encryptedPartnerID = sessionStorage.getItem("encryptedpartnerID");
    if (encryptedPartnerID) {
      const decryptedPartnerID = decryptData(encryptedPartnerID, secretKey);
      return decryptedPartnerID;
    }
    return null;
  };

  const secretKey = config.secretKey;
  const partnerId = getUsernameFromLocalStorage(secretKey);

  let parsedDate;
  let formattedDate;
  let orderDate;
  let formattedorderDate;

  const [reservation, setReservation] = React.useState([]);
  const [currentPage, setCurentPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [pageNumber, setpageNumber] = React.useState(1);
  const [customerName, setCustomerName] = React.useState();
  const [fromItems, setFromItems] = React.useState();
  const [toItems, setToItems] = React.useState();

  const [showFilterOptions, setShowFilterOptions] = React.useState(false);
  const [activeFilter, setActiveFilter] = React.useState();

  //Filter Search
  const [dropdownFilter, setDropdownOpen] = useState(false);
  const [itemDropdownFilter, setItemDropdownFilter] = useState();

  //Modal
  const [showModal, setShowModal] = useState(false);
  const [detailReservasi, setDetailReservasi] = useState([]);
  const [showModalCancel, setShowModalCancel] = useState(false);

  //Cancel Reservasi
  const [fileUpload, setFileUpload] = useState();
  const [resoanCancelReservasi, setResoanCancelReservasi] = useState("");
  const [partnerResvId, setPartnerResvId] = useState();

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  //Filter Search
  const handleDropdowFilter = () => {
    setDropdownOpen(!dropdownFilter);
    console.log(dropdownFilter);
  };

  //Modal Active
  const handdleModalActive = async (e) => {
    const idReservasi = e.currentTarget.getAttribute("data-reservation-id");
    try {
      const response = await axios.get(
        `${API_URLS.reservasi}/api/get_partner_reservation_detail?reservation_id=${idReservasi}`
      );
      console.log(idReservasi);

      setDetailReservasi(response.data.partner_reservation_detail);
      if (response.data.partner_reservation_detail.length === 0) {
        Swal.fire({
          title: "Oops!",
          text: "Data Detail Reservasi Tidak Ada",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      } else {
        setShowModal(!showModal);
      }
    } catch (error) {
      console.error("Error fetching reservation details:", error);
    }
  };

  //Modal Cancel Active
  const handleModalCancel = (e) => {
    const reservationId = e.currentTarget.getAttribute("data-reservation-id");
    setPartnerResvId(reservationId);
    setShowModalCancel(!showModalCancel);
    setShowModal(false);
  };
  const handdleCancelReservasi = async (e) => {
    try {
      const formData = new FormData();
      formData.append("partner_id", partnerId);
      formData.append("partner_reservation_id", partnerResvId);
      formData.append("reason", resoanCancelReservasi);
      formData.append("proof", fileUpload);

      setShowModalCancel(!showModalCancel);

      if (!resoanCancelReservasi || !fileUpload) {
        Swal.fire({
          title: "Oops!",
          text: "Data Tidak Lengkap, Harap Isi Semua Data",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        return;
      }

      Swal.fire({
        title: "Loading!",
        text: "Proses Pembatalan Reservasi",
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const response = await axios.post(
        `${API_URLS.reservasi}/api/cancel_partner_reservation`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer YOUR_ACCESS_TOKEN",
          },
        }
      );

      if (response.data.success === "Data Berhasil di Cancel") {
        Swal.close();
        Swal.fire({
          title: "Sukses",
          text: "Reservasi Berhasil di Batalkan",
          imageUrl: "/img/STK-20230906-WA0025.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: false,
          timer: 1500,
        });
        setResoanCancelReservasi("");
        setFileUpload(null);
      } else {
        Swal.close();
        Swal.fire({
          title: "Oops!",
          text: "Reservasi Gagal di Batalkan, Silahkan Coba Lagi",
          imageUrl: "/img/STK-20230906-WA0006.webp",
          imageWidth: 200,
          imageHeight: 200,
          imageAlt: "Custom image",
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    } catch (error) {
      Swal.close();
      console.error("Error fetching reservation details:", error);
    }
  };

  const handleFilter = (e) => {
    const value = e.target.getAttribute("filter");
    console.log(value);
    console.log(activeFilter);
    if (value == activeFilter) {
      setActiveFilter("");
    } else {
      setActiveFilter(value);
    }
    setShowFilterOptions(!showFilterOptions);
  };

  //Filter Search
  const handleItemDropdownFilter = (e) => {
    const value = e.currentTarget.getAttribute("filter");
    setItemDropdownFilter(value);
    setDropdownOpen(!dropdownFilter);
  };

  const paid =
    activeFilter === "paid"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";
  const arrival_today =
    activeFilter === "arrival_today"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";
  const cancel =
    activeFilter === "cancel"
      ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
      : "hover:bg-slate-200 text-slate-950";

  React.useEffect(() => {
    axios
      .get(
        API_URLS.reservasi +
          "/api/get_partner_reservation?partner_id=" +
          partnerId +
          "&customer_name=" +
          customerName +
          "&filter=" +
          activeFilter +
          "&page=" +
          pageNumber +
          `${
              itemDropdownFilter === "order_id" ? "&order_id=" + customerName : ""
          }`
      )
      .then((response) => {
        let reservations = response.data.partner_reservations.data;
        setFromItems(response.data.partner_reservations.from);
        setToItems(response.data.partner_reservations.to);
        let newReservation = [];
        for (let index = 0; index < reservations.length; index++) {
          newReservation.push({
            reservation_id: reservations[index].id,
            order_id: reservations[index].order_id,
            customer: reservations[index].partner_customer,
            total_ticket: reservations[index].quantity,
            amount_total: reservations[index].amount_total,
            date_plan: reservations[index].date_plan,
            date_order: reservations[index].created_at,
            booking_code: reservations[index].booking_code,
            state: reservations[index].state,
          });
        }
        setReservation(newReservation);
        setCurentPage(response.data.partner_reservations.current_page);
        setTotalPage(response.data.partner_reservations.last_page);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [partnerId, customerName, activeFilter, pageNumber]);

  useEffect(() => {
    console.log("Nilai detailReservasi telah diperbarui:", detailReservasi);
  }, [detailReservasi]);

  return (
    <>
      <LayoutMain>
        <div className="w-full px-5 md:px-10 pt-6">
          <strong className="inline-block mt-2 text-xl text-sekunder-60">
            Reservasi
          </strong>
          <div className="max-w-full w-full py-6 rounded-lg">
            <span className="inline-block font-semibold whitespace-nowrap">
              Daftar Reservasi
            </span>
            <div className="w-full flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4 py-2 mt-4">
              <div className="w-full flex flex-col lg:flex-row items-center gap-4">
                <div className="w-full lg:w-1/2 flex items-center">
                  <div className="w-full relative">
                    <input
                      onInput={(e) => {
                        setCustomerName(e.target.value);
                      }}
                      type="text"
                      id="serach-tabel"
                      placeholder="Cari Nama Pelanggan"
                      className="w-full px-10 py-3 border border-slate-300 bg-white dark:bg-slate-600 rounded-full focus:outline-none focus:border-primer-60 text-slate-800 dark:text-slate-50 text-sm focus:ring-2 focus:ring-primer-40"
                    />
                    <HiMiniMagnifyingGlass className="absolute top-3 left-3.5 text-xl" />
                    <div className="absolute right-3 top-3.5">
                      <div className="relative">
                        <button
                          className="w-fit flex items-center gap-2 text-sm"
                          onClick={handleDropdowFilter}
                        >
                          Filter Pencarian
                          <FaAngleDown />
                        </button>
                        <ul
                          className={`${
                            dropdownFilter === true ? "" : "hidden"
                          } absolute top-5 left-0 bg-white py-1 border rounded-lg shadow mt-4 z-10`}
                        >
                          <li
                            className={`w-full px-4 py-2 my-2 text-sm cursor-pointer ${
                              itemDropdownFilter === "nama-pelanggan"
                                ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
                                : "hover:bg-slate-200 text-slate-950"
                            }`}
                          >
                            <a
                              className="flex items-center gap-2 text-sm whitespace-nowrap"
                              onClick={(e) => handleItemDropdownFilter(e)}
                              filter="nama-pelanggan"
                            >
                              <FaCircleCheck
                                className={`${
                                  itemDropdownFilter === "nama-pelanggan"
                                    ? "block"
                                    : "hidden"
                                }`}
                              />
                              Nama Pelanggan
                            </a>
                          </li>
                          <li
                            className={`w-full px-4 py-2 my-2 text-sm cursor-pointer ${
                              itemDropdownFilter === "order_id"
                                ? "bg-sekunder-60 hover:bg-sekunder-40 text-white"
                                : "hover:bg-slate-200 text-slate-950"
                            }`}
                          >
                            <a
                              className="flex items-center gap-2 text-sm whitespace-nowrap"
                              onClick={(e) => handleItemDropdownFilter(e)}
                              filter="order_id"
                            >
                              <FaCircleCheck
                                className={`${
                                  itemDropdownFilter === "order_id"
                                    ? "block"
                                    : "hidden"
                                }`}
                              />
                              ID Order
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 flex items-center">
                  <div className="relative">
                    <button
                      id="filterButton"
                      type="button"
                      className="px-4 py-3 w-full lg:w-fit flex items-center justify-center gap-2 bg-sekunder-60 font-semibold text-white text-sm rounded-full hover:bg-sekunder-40 transition-colors duration-200 ease-in-out"
                      onClick={toggleFilterOptions}
                    >
                      <FaFilter className="text-sm" />
                      <span className="inline-block font-semibold whitespace-nowrap">
                        Filter
                      </span>
                    </button>

                    {showFilterOptions && (
                      <ul className="absolute top-10 left-0 bg-white py-1 border rounded-lg shadow mt-4 z-10">
                        <li
                          className={`w-full px-4 py-2  text-sm cursor-pointer my-1 ${paid}`}
                        >
                          <a
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            onClick={(e) => handleFilter(e)}
                            filter="paid"
                          >
                            <FaCircleCheck
                              className={`${
                                activeFilter === "paid" ? "block" : "hidden"
                              }`}
                            />
                            Berhasil
                          </a>
                        </li>
                        <li
                          className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${arrival_today}`}
                        >
                          <a
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            onClick={(e) => handleFilter(e)}
                            filter="arrival_today"
                          >
                            <FaCircleCheck
                              className={`${
                                activeFilter === "arrival_today"
                                  ? "block"
                                  : "hidden"
                              }`}
                            />
                            Kedatangan Hari Ini
                          </a>
                        </li>
                        <li
                          className={`w-full px-4 py-2 dark:hover:bg-slate-500 cursor-pointer my-1 ${cancel}`}
                        >
                          <a
                            className="flex items-center gap-2 text-sm whitespace-nowrap"
                            onClick={(e) => handleFilter(e)}
                            filter="cancel"
                          >
                            <FaCircleCheck
                              className={`${
                                activeFilter === "cancel" ? "block" : "hidden"
                              }`}
                            />
                            DiBatalkan
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <NavLink
                to={"/reservasi/create-reservasi"}
                className="w-fit px-4 py-3 rounded-full flex items-center justify-center gap-2 bg-primer-60 font-semibold text-white text-sm hover:bg-primer-80 transition-colors duration-200 ease-in-out whitespace-nowrap"
              >
                <HiPlus className="text-base" />
                Create Reservation
              </NavLink>
            </div>
            <div className="w-full py-4">
              {/* <!-- Desktop View Start --> */}
              <div className="hidden xl:block overflow-auto">
                <table className="w-full">
                  <thead className="bg-slate-100 rounded-lg">
                    <tr>
                      <th className="pl-6 pr-3 py-3 text-sm font-semibold tracking-wide text-left">
                        No
                      </th>
                      <th className="pl-3 pr-3 py-3 text-sm font-semibold tracking-wide text-left">
                        ID Pesanan
                      </th> 
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Jumlah Tiket
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Tipe Pesanan
                      </th>
                      <th className="p-3 text-sm font-semibold tracking-wide text-left">
                        Status
                      </th>
                      <th className="pr-6 pl-3 py-3 text-sm font-semibold tracking-wide text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y-2 divide-slate-100 dark:divide-slate-600">
                    {reservation.map((reservation, index) => {
                      orderDate = new Date(reservation.date_order);
                      formattedorderDate = format(
                        orderDate,
                        "eeee, dd MMMM yyyy HH:mm:ss",
                        { locale: id }
                      );
                      parsedDate = parseISO(reservation.date_plan);
                      formattedDate = format(parsedDate, "EEEE, dd MMMM yyyy", {
                        locale: id,
                      });
                      return (
                        <tr className="bg-white" key={index}>
                          <td className="pl-6 pr-3 py-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {fromItems + index}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {reservation.order_id}
                          </td>
                          
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {reservation.total_ticket}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {formatRupiah(reservation.amount_total)}
                          </td>
                          <td className="p-3 text-sm tracking-wide text-left whitespace-nowrap">
                            {reservation.state}
                          </td>
                          <td className="pr-6 pl-3 py-3 text-sm tracking-wide text-left">
                            <div className="w-fit flex item-center gap-2 px-3 py-2 justify-between">
                              <button
                                onClick={(e) => handleModalCancel(e)}
                                className="w-8 h-8 flex items-center justify-center rounded-lg bg-rose-50 text-rose-600 hover:bg-rose-600 hover:text-white transition-colors duration-200 ease-in-out"
                                title="batalkan pesanan"
                                data-reservation-id={reservation.reservation_id}
                              >
                                <FaCircleXmark />
                              </button>
                              <button
                                onClick={(e) => handdleModalActive(e)}
                                className="w-8 h-8 flex items-center justify-center rounded-lg bg-amber-50 text-amber-600 hover:bg-amber-600 hover:text-white transition-colors duration-200 ease-in-out cursor-pointer"
                                title="detail"
                                data-reservation-id={reservation.reservation_id}
                              >
                                <FaRegEye />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <!-- Desktop View End --> */}

              {/* <!-- Mobile View Start --> */}
              <div className="xl:hidden w-full">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {reservation.map((reservation, index) => {
                      orderDate = new Date(reservation.date_order);
                      formattedorderDate = format(
                        orderDate,
                        "eeee, dd MMMM yyyy HH:mm:ss",
                        { locale: id }
                      );
                      parsedDate = parseISO(reservation.date_plan);
                      formattedDate = format(parsedDate, "EEEE, dd MMMM yyyy", {
                        locale: id,
                      });
                      return (
                        <tr key={reservation.order_id}>
                          <td
                            onClick={(e) => handdleModalActive(e)}
                            data-reservation-id={reservation.reservation_id}
                          >
                            <Card className="w-full px-4 py-3 flex flex-col gap-3 border border-slate-300 rounded-lg my-2 cursor-pointer">
                              <div className="w-full flex items-center justify-between">
                                <strong className="text-base text-left">
                                  {reservation.customer.partner_customer_name}
                                </strong>
                                <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 whitespace-nowrap">
                                  {reservation.order_id}
                                </span>
                              </div>
                              <div className="w-full flex flex-col gap-2">
                                <div className="w-full flex item-center gap-2">
                                  <FaCalendarCheck className="text-lg" />
                                  <span className="text-left">
                                    {formattedDate}
                                  </span>
                                </div>
                                <div className="w-full flex item-center gap-2">
                                  <FaCalendarDay className="text-lg" />
                                  <span className="text-left">
                                    {formattedorderDate}
                                  </span>
                                </div>
                                <div className="w-full flex items-center justify-between py-2">
                                  <span className="text-sm text-left">
                                    {reservation.total_ticket} Tiket
                                  </span>
                                  <strong>
                                    {formatRupiah(reservation.amount_total)}
                                  </strong>
                                </div>
                                <div className="w-fit flex item-center gap-2 px-3 py-2 rounded-full bg-blue-50">
                                  <FaCircle className="text-blue-600 text-sm" />
                                  <span className="font-semibold whitespace-nowrap text-blue-600 text-xs uppercase">
                                    on process
                                  </span>
                                </div>
                              </div>
                            </Card>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <!-- Mobile View End --> */}
            </div>
            {/* <!-- Pagination Start --> */}
            <div className="max-w-full w-full pagination bg-white dark:bg-slate-800 px-6 py-4 rounded-b-lg">
              <ul className="flex items-center justify-end">
                <span
                  id="pagination-info"
                  className="block mr-4 text-sm text-slate-600 dark:text-slate-300"
                >
                  Showing page {currentPage} of {totalPage}
                </span>
                <li>
                  <button
                    onClick={() => setpageNumber(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`flex items-center justify-center h-10 w-10 mr-2 rounded-lg ${
                      currentPage === 1
                        ? "bg-slate-300 text-slate-600 cursor-not-allowed"
                        : "bg-primer-20 text-primer-60 hover:bg-primer-60 t hover:text-white cursor-pointer"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => setpageNumber(currentPage + 1)}
                    disabled={currentPage === totalPage}
                    className={`flex items-center justify-center h-10 w-10 mr-2 rounded-lg ${
                      currentPage === totalPage
                        ? "bg-slate-300 text-slate-600 cursor-not-allowed"
                        : "bg-primer-20 text-primer-60 hover:bg-primer-60 t hover:text-white cursor-pointer"
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
            {/* <!-- Pagination End --> */}
          </div>
          <div className="px-10 lg:pl-10 lg:pr-16 py-4 border-t-2 border-t-slate-100 dark:border-t-slate-700">
            <p className="text-sm text-slate-400">
              Copyright © 2023 Aplikasi By IT SALOKA
            </p>
          </div>
        </div>
      </LayoutMain>
      <section
        className={`modal-container ${
          showModal ? "flex" : "hidden"
        } items-center justify-center max-w-full w-full max-h-screen h-full fixed top-0 left-0 z-[9999] px-5 py-5`}
      >
        <div
          className={`show-modal w-full max-w-full xl:max-w-5xl max-h-screen lg:max-h-fit h-full lg:h-fit overflow-auto rounded-lg bg-white dark:bg-slate-800 ${
            showModal ? "active" : ""
          }`}
        >
          <div className="w-full flex items-center justify-between relative p-4 lg:p-6 border-b border-slate-100 dark:border-slate-600">
            <strong>Detail Reservasi</strong>
            <button
              className="cursor-pointer"
              onClick={() => setShowModal(false)}
            >
              <FaCircleXmark className="text-2xl" />
            </button>
          </div>
          <div className="w-full px-6 py-6 lg:px-6 lg:pt-6 lg:pb-9">
            {detailReservasi.map((item, index) => (
              <div
                key={index}
                className="w-full grid grid-cols-1 lg:grid-cols-3 gap-6"
              >
                {/* <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                    ID
                  </span>
                  <span className="text-[13px]">{item.id}</span>
                </div> */}
                {/* <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Partner Reservasi ID
                  </span>
                  <span className="text-[13px]">
                    {item.partner_reservation_id}
                  </span>
                </div> */}
                <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Price Unit
                  </span>
                  <span className="text-[13px]">
                    {formatRupiah(item.price_unit)}
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Base Price
                  </span>
                  <span className="text-[13px]">
                    {formatRupiah(item.base_price)}
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Subtotal
                  </span>
                  <span className="text-[13px]">
                    {formatRupiah(item.subtotal)}
                  </span>
                </div>
                {/* <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Partner ID
                  </span>
                  <span className="text-[13px]">
                    {item.partner_customer.partner_id}
                  </span>
                </div> */}
                <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Nama
                  </span>
                  <span className="text-[13px]">
                    {item.partner_customer.partner_customer_name}
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Nomor Telphone
                  </span>
                  <span className="text-[13px]">
                    {item.partner_customer.partner_customer_phone}
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Email
                  </span>
                  <span className="text-[13px]">
                    {item.partner_customer.partner_customer_email}
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400">
                    Provinsi
                  </span>
                  <span className="text-[13px] whitespace-nowrap">
                  {item.partner_customer.partner_customer_province}
                  </span>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                    Kota
                  </span>
                  <span className="text-[13px]">
                    {item.partner_customer.partner_customer_city}
                  </span>
                </div>
                <div className="xl:hidden w-full flex flex-col gap-2">
                  <span className="text-[13px] font-semibold text-slate-600 dark:text-slate-400 whitespace-nowrap">
                    Setting Batalkan Reservasi
                  </span>
                  <button
                    onClick={(e) => handleModalCancel(e)}
                    type="button"
                    className="w-fit text-white bg-rose-700 hover:bg-rose-800 font-medium rounded-lg text-sm px-3 py-2.5 text-center dark:bg-rose-600 dark:hover:bg-rose-700"
                    data-reservasi-id={item.partner_reservation_id}
                  >
                    <FaCircleXmark className="text-lg" />
                  </button>
                  <div
                    id="edit-email"
                    role="tooltip"
                    className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                  >
                    Edit Email
                    <div className="tooltip-arrow" data-popper-arrow></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        className={`modal-container ${
          showModalCancel ? "flex" : "hidden"
        } items-center justify-center max-w-full w-full max-h-screen h-full fixed top-0 left-0 z-[9999] px-5`}
      >
        <div
          className={`show-modal w-full max-w-full md:max-w-lg rounded-lg bg-white dark:bg-slate-700 p-4 ${
            showModalCancel ? "active" : " "
          }`}
        >
          <div className="w-full flex items-center justify-between relative pb-6 border-b border-slate-100">
            <strong>Upload Lampiran</strong>
            <button onClick={() => setShowModalCancel(false)}>
              <FaCircleXmark className="text-2xl" />
            </button>
          </div>
          <div className="w-full pt-6 px-4">
            <div className="w-full flex flex-col gap-3 py-2">
              <label htmlFor="file-upload" className="text-sm font-semibold">
                Upload File Pendukung
              </label>
              <input
                type="file"
                name="uplaod-file"
                id="uplaod-file"
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                onChange={(e) => setFileUpload(e.target.files[0])}
              />
            </div>
            <div className="w-full py-2">
              <textarea
                name="reason"
                id="reason"
                cols="10"
                rows="5"
                className="w-full px-4 py-3 border border-slate-300 dark:border-slate-600 rounded-lg focus:outline-none focus:border-informasi-60 text-slate-700 dark:text-slate-50 bg-white dark:bg-slate-800 text-sm focus:ring-2 focus:ring-informasi-40"
                placeholder="Masukan Alasan Melakukan Pembatalan Reservasi"
                value={resoanCancelReservasi}
                onChange={(e) => setResoanCancelReservasi(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="w-full flex item-center justify-end gap-3 mt-6">
            <button
              className="close-modal px-4 py-3 rounded-lg bg-slate-300 text-slate-950 text-sm hover:bg-slate-200 w-fit"
              onClick={() => setShowModalCancel(false)}
            >
              Batal
            </button>
            <button
              id="upload-file"
              type="submit"
              form="form_data"
              className="close-modal px-4 py-3 rounded-lg text-slate-50 text-sm bg-primer-60 hover:bg-primer-80 transition-colors duration-200 ease-in-out w-fit"
              onClick={handdleCancelReservasi}
            >
              Batalkan Reservasi
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Insentif;
