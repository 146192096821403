import { NavLink } from "react-router-dom";
import LayoutMain from "../../layouts/layout-main";

const NotaTransaksiTiket = () => {
    return (
        <>
            <LayoutMain>
                <div className="w-full px-4 md:px-10 pt-6">
                    <strong className="inline-block mt-2 text-xl text-sekunder-60">Data Pemesan</strong>
                    <div className="w-full px-4 py-4 mt-6">
                        <div className="w-full flex flex-col gap-2">
                            <strong>Data Pemesan</strong>
                            <span className="text-sm text-slate-600">Pastika Semua Data Terisi dengan Benar</span>
                            <div className="w-full xl:max-w-md p-6 mt-4 bg-slate-100 dark:bg-slate-800 rounded-lg relative">
                                <div className="w-10 h-10 rounded-full bg-white dark:bg-slate-900 absolute -top-6 left-[46%]"></div>
                                <div className="w-full flex flex-col gap-2 pb-4 border-b-2 border-gray-200">
                                    <strong className="text-sm">Detail Pemesan</strong>
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-sm">Tgl Kedatangan</span>
                                        <span className="text-sm whitespace-nowrap">11 Desember 2023</span>
                                    </div>
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-sm">No Telpon</span>
                                        <span className="text-sm whitespace-nowrap">081326010378</span>
                                    </div>
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-sm">Email</span>
                                        <span className="text-sm whitespace-nowrap">672020154@student.uksw.edu</span>
                                    </div>
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-sm">Alamat</span>
                                        <span className="text-sm whitespace-nowrap">Jalan Kemiri Candi, Salatiga</span>
                                    </div>
                                </div>
                                <div className="w-full flex flex-col gap-2 mt-6 pb-4 border-b-2 border-dashed border-gray-200">
                                    <strong className="text-sm">Detail Pembayaran</strong>
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-sm">Tiket</span>
                                        <span className="text-sm whitespace-nowrap">Promo Oktober - Weekday</span>
                                    </div>
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-sm">Qty</span>
                                        <span className="text-sm whitespace-nowrap">10</span>
                                    </div>
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-sm">Subtotal</span>
                                        <span className="text-sm whitespace-nowrap">Rp 1.500.00</span>
                                    </div>
                                </div>
                                <div className="w-full flex flex-col gap-2 py-4 border-b-2 border-dashed border-gray-200">
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-sm">Total</span>
                                        <span className="font-semibold whitespace-nowrap">Rp 1.500.00</span>
                                    </div>
                                </div>
                                <div className="w-full my-2">
                                    <NavLink type="submit" className="w-full px-4 py-3 rounded-full bg-primer-60 text-white text-center font-semibold hover:bg-primer-40 mt-4 uppercase">Checkout</NavLink>
                                </div>
                                <div className="w-10 h-10 rounded-full bg-white dark:bg-slate-900 absolute -bottom-6 left-[46%]"></div>
                            </div>
                        </div>
                    </div>
                    <div className="px-10 lg:pl-10 lg:pr-16 mt-12 py-4 border-t-2 border-t-slate-100 dark:border-t-slate-700">
                        <p className="text-sm text-slate-400">Copyright © 2023  Aplikasi By IT SALOKA</p>
                    </div>
                </div>
            </LayoutMain>
        </>
    );
}

export default NotaTransaksiTiket;